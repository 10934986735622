const signIn = [
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "SignIn" */ '@/views/signin/SignIn')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/signin/ResetPassword')
  },
  {
    path: '/forgot-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "ForgotPassword" */ '@/views/signin/ForgotPassword')
  }
]

export default signIn
