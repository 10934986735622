// State
const state = JSON.parse(window.localStorage.getItem('appshell')) || {
  isSidebarOpen: false,
  isLoading: false,
  isSectionLoading: false,
  isDataFetchingFailed: false,
  isPopupOpen: null
}

// Gettetrs
const getters = {
  isSidebarOpen: (state) => state.isSidebarOpen,
  isLoading: (state) => state.isLoading,
  isSectionLoading: (state) => state.isSectionLoading,
  isDataFetchingFailed: (state) => state.isDataFetchingFailed,
  isPopupOpen: (state) => state.isPopupOpen
}

// Actions
const actions = {
  // ...
}

// Mutations
const mutations = {
  TOGGLE_POPUP(state, payload) {
    state.isPopupOpen = state.isPopupOpen === payload ? null : payload
  },
  HIDE_POPUP(state, payload) {
    state.isPopupOpen = null
  },
  toggleSidebar(state) {
    state.isSidebarOpen = !state.isSidebarOpen
    window.localStorage.setItem('appshell', JSON.stringify(state))
  },
  closeSidebar(state) {
    state.isSidebarOpen = false
    window.localStorage.setItem('appshell', JSON.stringify(state))
  },
  startLoading(state) {
    state.isLoading = true
  },
  endLoading(state) {
    state.isLoading = false
  },
  startSectionLoading(state) {
    state.isSectionLoading = true
  },
  endSectionLoading(state) {
    state.isSectionLoading = false
  },
  setDataFetchingState(state, payload) {
    state.isDataFetchingFailed = payload
  }
}

export default {
  strict: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
