import api from '@/api'
import i18n from '@/setup/i18n'

const state = { weatherStations: [], showAddWeatherStationModal: false, showRemoveWeatherStationModal: false, notifications: null }

const mutations = {
  SET_WEATHER_STATIONS(state, payload) {
    state.weatherStations = payload
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  }
}

const actions = {
  fetchWeatherStations({ commit, rootState }) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve) => {
      api.weatherStations
        .getAllWeatherStations(rootState.workspaces.currentWorkspace.id)
        .then((response) => {
          commit('SET_WEATHER_STATIONS', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          resolve(response.data)
        })
        .catch(() => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
        })
    })
  },
  createWeatherStation({ commit, rootState, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })
    console.log(payload)

    return new Promise((resolve) => {
      api.weatherStations
        .createWeatherStation(rootState.workspaces.currentWorkspace.id, payload)
        .then(() => {
          dispatch('fetchWeatherStations')
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('weatherStations.createSuccess') })
          resolve()
        })
        .catch((error) => {
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('weatherStations.createError')
          })
        })
    })
  },
  updateWeatherStation({ commit, rootState, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve) => {
      api.weatherStations
        .updateWeatherStation(rootState.workspaces.currentWorkspace.id, payload.id, payload.data)
        .then(() => {
          dispatch('fetchWeatherStations')
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('weatherStations.updateSuccess') })
          resolve()
        })
        .catch((error) => {
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('weatherStations.updateError')
          })
        })
    })
  },
  deleteWeatherStation({ commit, rootState, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve) => {
      api.weatherStations
        .deleteWeatherStation(rootState.workspaces.currentWorkspace.id, payload)
        .then(() => {
          dispatch('fetchWeatherStations')
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('weatherStations.deleteSuccess') })
          resolve()
        })
        .catch((error) => {
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('weatherStations.deleteError')
          })
        })
    })
  }
}

const getters = {}

export default { namespaced: true, state, getters, actions, mutations }
