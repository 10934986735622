const account = [
  {
    name: 'Account',
    path: '/account',
    meta: {
      auth: true,
      permission: []
    },
    component: () => import(/* webpackChunkName: "Account" */ '@/views/account/Index')
  }
]

export default account
