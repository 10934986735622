import Router from 'vue-router'
import store from '@/store'
import SignIn from './signIn'
import Account from './account'

const beforeEnter = (to, from, next) => {
  // if (to.matched.some(record => record.meta.auth)) {
  //   const matchedRole = !!store.getters['account/userRoles'].find(role => to.meta.permission.includes(role))

  //   if (!to.meta.permission || !to.meta.permission.length || matchedRole) {
  //     if (to.path === '/users' || to.path === '/account') {
  //       next()
  //     } else {
  //       if (store.getters['workspaces/currentWorkspace']) next()
  //       else next('/users')
  //     }
  //   } else {
  //     next('/redirect')
  //   }
  // } else {
  //   next()
  // }

  next()
}

const config = {
  routes: [
    {
      path: '/',
      redirect: '/location'
    },
    {
      name: 'Location',
      path: '/location',
      meta: {
        auth: true,
        permission: []
      },
      beforeEnter,
      component: () => import(/* webpackChunkName: "App" */ '@/views/Index'),
      children: [
        {
          name: 'LocationDashboard',
          path: ':id/dashboard',
          meta: {
            auth: true,
            permission: []
          },
          component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/app/Dashboard')
        },
        {
          name: 'LocationForms',
          path: ':id/forms',
          meta: {
            auth: true,
            permission: []
          },
          component: () => import(/* webpackChunkName: "Forms" */ '@/views/app/Forms')
        },
        {
          name: 'LocationAnnouncements',
          path: ':id/communication',
          meta: {
            auth: true,
            permission: []
          },
          component: () => import(/* webpackChunkName: "Announcements" */ '@/views/app/Announcements')
        },
        {
          name: 'LocationUsers',
          path: ':id/users',
          meta: {
            auth: true,
            permission: []
          },
          component: () => import(/* webpackChunkName: "Users" */ '@/views/app/Users')
        },
        {
          name: 'LocationWidgets',
          path: ':id/widgets',
          meta: {
            auth: true,
            permission: []
          },
          component: () => import(/* webpackChunkName: "Widgets" */ '@/views/app/Widgets')
        },
        {
          name: 'LocationSettings',
          path: ':id/object-settings',
          meta: {
            auth: true,
            permission: []
          },
          component: () => import(/* webpackChunkName: "Widgets" */ '@/views/app/ObjectSettings')
        },
        {
          name: 'LocationWeatherStations',
          path: ':id/weather-stations',
          meta: {
            auth: true,
            permission: []
          },
          component: () => import(/* webpackChunkName: "Widgets" */ '@/views/app/WeatherStations')
        }
      ]
    },
    ...SignIn,
    ...Account,
    {
      name: 'OperatorForm',
      path: '/operator-form',
      component: () => import(/* webpackChunkName: "ActivateAccount" */ '@/views/OperatorForm')
    },
    {
      name: 'Error404',
      path: '*',
      component: () => import(/* webpackChunkName: "Error404" */ '@/views/errors/Error404')
    }
  ],
  linkActiveClass: 'active'
}

const router = new Router(config)

export default router
