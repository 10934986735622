import Vue from 'vue'

Vue.mixin({
  methods: {
    getRolesForAppType(roles, type) {
      // if (type === 'HOTEL_TV' || type === 'DIGITAL_SIGNAGE') {
      //   return roles.split(', ').filter(r => type === 'HOTEL_TV' ? r.includes('TV') : r.includes('DS'))
      // }
      // return
      return type === 'HOTEL_TV' || type === 'DIGITAL_SIGNAGE'
        ? roles.split(', ').filter((r) => (type === 'HOTEL_TV' ? r.includes('TV') : r.includes('DS')))
        : false
    },
    can(roles, appType = null) {
      // if (!roles) {
      //   console.error('A role is not defined!')
      //   return
      // }

      // if (typeof roles !== 'string') {
      //   console.error('A role has to be a valid string!')
      //   return
      // }

      // return roles.includes(',')
      //   ? this.$store.getters['account/userRoles'].find(r => {
      //       const appTypesRole = this.getRolesForAppType(roles, appType)
      //       return this.$store.getters['account/userRoles'].find(r => {
      //         return appTypesRole ? appTypesRole.includes(r) : roles.includes(r)
      //       })
      //     })
      //   : this.$store.getters['account/userRoles'].includes(roles)

      return true
    }
  }
})
