import api from '@/api'
import i18n from '@/setup/i18n'

const state = {
  showAnnouncementCreator: false,
  announcements: null,
  notifications: null,
  announcementToEdit: null,
  languages: null,
  announcementTypes: [
    {
      name: i18n.t('announcements.types.warning'),
      value: 'WARNING',
      icon: 'announcement-warning'
    },
    {
      name: i18n.t('announcements.types.informational'),
      value: 'INFORMATIONAL',
      icon: 'announcement-info'
    },
    {
      name: i18n.t('announcements.types.promotional'),
      value: 'PROMOTIONAL',
      icon: 'announcement-offer'
    },
    {
      name: i18n.t('announcements.types.advertising'),
      value: 'ADVERTISING',
      icon: 'announcement-ad'
    }
  ]
}

const mutations = {
  SET_ANNOUNCEMENTS(state, payload) {
    state.announcements = payload
  },
  SET_ANNOUNCEMENTS_CREATOR(state, payload) {
    state.showAnnouncementCreator = payload
  },
  SET_ANNOUNCEMENT_TO_EDIT(state, payload) {
    state.announcementToEdit = payload
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },
  SET_LANGUAGES(state, payload) {
    state.languages = payload
  }
}

const actions = {
  fetchCurrentWorkspaceAnnouncements({ commit, rootState }) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve) => {
      api.announcements
        .getAnnouncements(rootState.workspaces.currentWorkspace.id)
        .then((response) => {
          commit('SET_ANNOUNCEMENTS', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          resolve(response.data)
        })
        .catch(() => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
        })
    })
  },
  fetchLanguages({ commit, rootState }) {
    return new Promise((resolve) => {
      api.announcements
        .getLanguages(rootState.workspaces.currentWorkspace.id)
        .then((response) => {
          commit('SET_LANGUAGES', response.data)
          resolve(response.data)
        })
        .catch((e) => {
          console.error('Error while fetching languages', e)
        })
    })
  },
  createAnnouncementsInCurrentLocation({ commit, rootState, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('background', payload.background)
      formData.append('logo', payload.logo)
      formData.append('request', new Blob([JSON.stringify(payload.request)], { type: 'application/json' }))

      api.announcements
        .createAnnouncement(rootState.workspaces.currentWorkspace.id, formData)
        .then(() => {
          dispatch('fetchCurrentWorkspaceAnnouncements')
          commit('appshell/endSectionLoading', null, { root: true })
          resolve()
        })
        .catch((error) => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('announcements.createError')
          })
          reject()
        })
    })
  },
  updateAnnouncementsInCurrentLocation({ state, commit, rootState, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      const id = payload.request.id
      const formData = new FormData()

      const computedRequest = { ...payload.request, '@type': payload.request.type }
      delete computedRequest.type

      formData.append('background', payload.background)
      formData.append('logo', payload.logo)
      formData.append('request', new Blob([JSON.stringify(computedRequest)], { type: 'application/json' }))

      api.announcements
        .updateAnnouncement(rootState.workspaces.currentWorkspace.id, id, formData)
        .then(() => {
          dispatch('fetchCurrentWorkspaceAnnouncements')
          commit('appshell/endSectionLoading', null, { root: true })
          resolve()
        })
        .catch((error) => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('announcements.updateError')
          })
          reject()
        })
    })
  },
  archiveAnnouncementsInCurrentLocation({ commit, rootState, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve) => {
      api.announcements
        .archiveAnnouncement(rootState.workspaces.currentWorkspace.id, payload)
        .then(() => {
          dispatch('fetchCurrentWorkspaceAnnouncements')
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('announcements.archiveSuccess') })
        })
        .catch((error) => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('announcements.archiveError')
          })
        })
    })
  },
  deleteAnnouncementsInCurrentLocation({ commit, rootState, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve) => {
      api.announcements
        .deleteAnnouncement(rootState.workspaces.currentWorkspace.id, payload)
        .then(() => {
          dispatch('fetchCurrentWorkspaceAnnouncements')
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('announcements.deleteSuccess') })
          resolve()
        })
        .catch((error) => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('announcements.deleteError')
          })
        })
    })
  }
}

const getters = {}

export default { namespaced: true, state, getters, actions, mutations }
