import axios from 'axios'

export default {
  getUsersInPlace: (locationId) => axios.get(`/api/v1/location/${locationId}/users`),
  createUserInPlace: (locationId, data) => axios.post(`/api/v1/location/${locationId}/users`, data),
  removeUser: (email) => axios.delete(`/api/v1/users?email=${encodeURIComponent(email)}`),
  editProfile: (email, data) =>
    axios.put(`/api/v1/users/profile?email=${encodeURIComponent(email)}`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }),
  resetPassword: (email) => axios.post(`/api/v1/users/remind-password?email=${encodeURIComponent(email)}`)
}
