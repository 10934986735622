import i18n from '@/setup/i18n'

export const fields = {
  skiLiftTraffic: [
    {
      name: i18n.t('forms.fields.traffic.options.none'),
      value: 'NONE'
    },
    {
      name: i18n.t('forms.fields.traffic.options.scarce'),
      value: 'SCARCE'
    },
    {
      name: i18n.t('forms.fields.traffic.options.low'),
      value: 'LOW'
    },
    {
      name: i18n.t('forms.fields.traffic.options.moderate'),
      value: 'MODERATE'
    },
    {
      name: i18n.t('forms.fields.traffic.options.high'),
      value: 'HIGH'
    },
    {
      name: i18n.t('forms.fields.traffic.options.veryHigh'),
      value: 'HIGH'
    }
  ],
  windDirection: [
    {
      value: 'N',
      name: i18n.t('forms.fields.windDirection.options.n')
    },
    {
      value: 'NE',
      name: i18n.t('forms.fields.windDirection.options.ne')
    },
    {
      value: 'E',
      name: i18n.t('forms.fields.windDirection.options.e')
    },
    {
      value: 'SE',
      name: i18n.t('forms.fields.windDirection.options.se')
    },
    {
      value: 'S',
      name: i18n.t('forms.fields.windDirection.options.s')
    },
    {
      value: 'SW',
      name: i18n.t('forms.fields.windDirection.options.sw')
    },
    {
      value: 'W',
      name: i18n.t('forms.fields.windDirection.options.w')
    },
    {
      value: 'NW',
      name: i18n.t('forms.fields.windDirection.options.nw')
    }
  ],
  snowType: [
    {
      value: 'FAKE',
      name: i18n.t('forms.fields.snowType.options.fake')
    },
    {
      value: 'FLUFFY',
      name: i18n.t('forms.fields.snowType.options.fluffy')
    },
    {
      value: 'GYPSUM',
      name: i18n.t('forms.fields.snowType.options.gypsum')
    },
    {
      value: 'COVERING_OF_ICE',
      name: i18n.t('forms.fields.snowType.options.covering_of_ice')
    },
    {
      value: 'ICE',
      name: i18n.t('forms.fields.snowType.options.ice')
    },
    {
      value: 'WET',
      name: i18n.t('forms.fields.snowType.options.wet')
    },
    {
      value: 'GRANULAR',
      name: i18n.t('forms.fields.snowType.options.granular')
    },
    {
      value: 'FIRN',
      name: i18n.t('forms.fields.snowType.options.firn')
    }
  ],
  avalancheDanger: [
    {
      value: 0,
      name: i18n.t('forms.fields.avalancheDanger.options.none')
    },
    {
      value: 1,
      name: i18n.t('forms.fields.avalancheDanger.options.low')
    },
    {
      value: 2,
      name: i18n.t('forms.fields.avalancheDanger.options.high')
    }
  ],
  liftType: [
    {
      value: 'CHAIRLIFT',
      name: i18n.t('objects.lift.types.chairLift'),
      icon: 'chairlift'
    },
    {
      value: 'CABLEWAY',
      name: i18n.t('objects.lift.types.cableway'),
      icon: 'gondola'
    },
    {
      value: 'GONDOLA',
      name: i18n.t('objects.lift.types.gondola'),
      icon: 'gondola'
    },
    {
      value: 'FURNICULAR_RAILWAY',
      name: i18n.t('objects.lift.types.furnicularRailway'),
      icon: 'gondola'
    },
    {
      value: 'T_BAR_LIFT',
      name: i18n.t('objects.lift.types.tBarLift'),
      icon: 'tbarlift'
    },
    {
      value: 'PLATTER_LIFT',
      name: i18n.t('objects.lift.types.platterLift'),
      icon: 'platterlift'
    },
    {
      value: 'BASKET_LIFT',
      name: i18n.t('objects.lift.types.basketLift'),
      icon: 'gondola'
    },
    {
      value: 'MOVING_WALKWAY',
      name: i18n.t('objects.lift.types.movingWalkway'),
      icon: 'moving-walkway'
    },
    {
      value: 'UNSUPPORTED_LIFT',
      name: i18n.t('objects.lift.types.unsupportedLift'),
      icon: 'unsupportedlift'
    }
  ],
  state: [
    {
      name: i18n.t('objects.run.states.open'),
      value: 'OPEN',
      status: 'OPEN'
    },
    {
      name: i18n.t('objects.run.states.inPreparation'),
      value: 'IN_PREPARATION',
      status: 'IN_PREPARATION'
    },
    {
      name: i18n.t('objects.run.states.closed'),
      value: 'CLOSED',
      status: 'CLOSED'
    }
  ],
  status: [
    {
      name: i18n.t('objects.lift.states.open'),
      value: 'OPEN',
      status: 'OPEN'
    },
    {
      name: i18n.t('objects.lift.states.closed'),
      value: 'CLOSED',
      status: 'CLOSED'
    },
    {
      name: i18n.t('objects.lift.states.temporarilyClosed'),
      value: 'TEMPORARILY_CLOSED',
      status: 'TEMPORARILY_CLOSED'
    }
  ],
  skiRunTraffic: [
    {
      name: i18n.t('forms.fields.skiRunTraffic.options.overloaded'),
      addition: i18n.t('forms.fields.skiRunTraffic.options.overloadedAddon'),
      value: 'OVERLOADED'
    },
    {
      name: i18n.t('forms.fields.skiRunTraffic.options.high'),
      addition: i18n.t('forms.fields.skiRunTraffic.options.highAddon'),
      value: 'HIGH'
    },
    {
      name: i18n.t('forms.fields.skiRunTraffic.options.moderate'),
      addition: i18n.t('forms.fields.skiRunTraffic.options.moderateAddon'),
      value: 'MODERATE'
    },
    {
      name: i18n.t('forms.fields.skiRunTraffic.options.low'),
      addition: i18n.t('forms.fields.skiRunTraffic.options.lowAddon'),
      value: 'LOW'
    },
    {
      name: i18n.t('forms.fields.skiRunTraffic.options.scarce'),
      addition: i18n.t('forms.fields.skiRunTraffic.options.scarceAddon'),
      value: 'SCARCE'
    },
    {
      name: i18n.t('forms.fields.skiRunTraffic.options.none'),
      addition: i18n.t('forms.fields.skiRunTraffic.options.noneAddon'),
      value: 'NONE'
    }
  ],
  time: [
    {
      value: 24,
      name: '24h'
    },
    {
      value: 22,
      name: '22h'
    },
    {
      value: 20,
      name: '20h'
    },
    {
      value: 18,
      name: '18h'
    },
    {
      value: 16,
      name: '16h'
    },
    {
      value: 14,
      name: '14h'
    },
    {
      value: 12,
      name: '12h'
    },
    {
      value: 10,
      name: '10h'
    },
    {
      value: 8,
      name: '8h'
    },
    {
      value: 6,
      name: '6h'
    },
    {
      value: 4,
      name: '4h'
    },
    {
      value: 2,
      name: '2h'
    },
    {
      value: 0,
      name: '0h'
    }
  ],
  skiRunStatus: [
    {
      name: i18n.t('forms.fields.skiRunStatus.options.open'),
      value: 'OPEN',
      status: 'OPEN'
    },
    {
      name: i18n.t('forms.fields.skiRunStatus.options.inPreparation'),
      value: 'IN_PREPARATION',
      status: 'IN_PREPARATION'
    },
    {
      name: i18n.t('forms.fields.skiRunStatus.options.closed'),
      value: 'CLOSED',
      status: 'CLOSED'
    }
  ],
  skiLiftStatus: [
    {
      name: i18n.t('forms.fields.skiLiftStatus.options.open'),
      value: 'OPEN',
      status: 'OPEN'
    },
    {
      name: i18n.t('forms.fields.skiLiftStatus.options.closed'),
      value: 'CLOSED',
      status: 'CLOSED'
    },
    {
      name: i18n.t('forms.fields.skiLiftStatus.options.temporarilyClosed'),
      value: 'TEMPORARILY_CLOSED',
      status: 'TEMPORARILY_CLOSED'
    }
  ]
}

export const icons = {
  status: 'status',
  state: 'status',
  traffic: 'lift',
  skiRunTraffic: 'track',
  windDirection: 'wind',
  windStrength: 'wind',
  precipitationPeriod: 'shower',
  precipitationType: 'shower',
  precipitation: 'shower',
  snowType: 'snow',
  averageSnowCoverThickness: 'snow',
  snowCoverThickness: 'snow',
  perceivedTemperature: 'temperature',
  temperature: 'temperature',
  humidity: 'temperature',
  hoursOfSun: 'sun',
  atmosphericPressure: 'pressure',
  avalancheDanger: 'avalanche'
}

export function getValue(param, value) {
  if (fields[param]) {
    const field = fields[param].find((o) => o.value === value)

    return field ? field.name : '-'
  } else {
    switch (param) {
      case 'precipitationPeriod':
        return `${value}h`
      case 'humidity':
        return `${value}%`
      case 'windStrength':
        return `${value}km/h`
      case 'snowCoverThickness':
        return `${value}cm`
      case 'perceivedTemperature':
        return `${value}°C`
      case 'temperature':
        return `${value}°C`
      case 'hoursOfSun':
        return `${value}h`
      default:
        return value
    }
  }
}
