import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach((key) => {
    const locale = key.match(/([A-Za-z0-9-_]+)\//i)[0].slice(0, -1)

    if (locale && locale.length > 1) {
      messages[locale] = {}
    }
  })
  locales.keys().forEach((key) => {
    const locale = key.match(/([A-Za-z0-9-_]+)\//i)[0].slice(0, -1)
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {
      const fields = matched[1]
      messages[locale][fields] = locales(key)
    }
  })

  return messages
}

export default new VueI18n({
  locale: localStorage.getItem('locale') || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: 'en',
  silentTranslationWarn: false,
  messages: loadLocaleMessages()
})
