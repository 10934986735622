<template lang="pug">
#app
  .app__loader(:class="{ 'app__loader--visible': isLoading }")

  template(v-if="$auth.ready()")
    transition(name="route", mode="out-in")
      router-view

    vue-snotify

    portal-target(name="popups", :transition="fadeTransition", multiple)
    portal-target(name="dialogs", multiple, :transition="fadeTransition")
    portal-target(name="creator", :transition="fadeTransition")
  .app__version V1.02
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters({
      isAuthenticated: 'account/isAuthenticated',
      user: 'account/user',
      workspaces: 'workspaces/workspaces',
      isLoading: 'appshell/isLoading',
      isSectionLoading: 'appshell/isSectionLoading',
      version: 'appshell/version',
      isDataFetchingFailed: 'appshell/isDataFetchingFailed'
    }),
    fadeTransition() {
      return {
        functional: true,
        render(h, context) {
          return h('transition', { props: { name: 'fade', mode: 'out-in' } }, context.children)
        }
      }
    }
  },
  watch: {
    $route: {
      handler: 'updateFetchingStates',
      immediate: true
    }
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        console.log('UPDATE')
      })
    }
  },
  methods: {
    ...mapMutations({
      endSectionLoading: 'appshell/endSectionLoading',
      setDataFetchingState: 'appshell/setDataFetchingState'
    }),
    ...mapActions({
      fetchWorkspaces: 'workspaces/fetchWorkspaces'
    }),
    updateFetchingStates() {
      if (this.isDataFetchingFailed) this.setDataFetchingState(false)
      if (this.isSectionLoading) this.endSectionLoading()
      if (!this.workspaces.length && this.isAuthenticated) this.fetchWorkspaces()
    }
  }
}
</script>

<style lang="scss">
.mce-notification-warning {
  display: none;
  visibility: hidden;
}
.app__loader {
  position: absolute;
  left: 0;
  width: 33.3333vw;
  height: 2px;
  background-color: $color--primary;
  visibility: hidden;
  opacity: 0;
  @include transition--primary;

  @keyframes appLoading {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(300%, 0, 0);
    }
  }
  animation: 1.5s appLoading infinite linear;

  &--visible {
    visibility: visible;
    opacity: 1;
  }
}

.app__version {
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  font-size: 0.7rem;
  font-weight: $font--medium;
  color: white;
  letter-spacing: 0.1em;
  opacity: 0.2;
  z-index: 1000;
}
</style>
