<template lang="pug">
button.bt-button(:class="[size, variant, type, { disabled }, { block }]", :type="nativeType", @click="(e) => $emit('click', e)")
  bt-loader(v-if="loading", :button="true")
  slot(v-else)
</template>

<script>
import BtLoader from '@/components/BtLoader/index'

export default {
  components: {
    BtLoader
  },
  props: {
    size: {
      validator: (value) => {
        return ['xsmall', 'small', 'large', 'default'].indexOf(value) !== -1
      }
    },
    variant: {
      validator: (value) => {
        return ['outline', 'link'].indexOf(value) !== -1
      }
    },
    type: {
      validator: (value) => {
        return ['error', 'success', 'danger'].indexOf(value) !== -1
      }
    },
    nativeType: {
      type: String,
      default: 'button',
      validator: (value) => ['button', 'submit', 'reset'].indexOf(value) !== -1
    },
    loading: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    add: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.bt-button {
  @include reset;

  font-size: 14px;
  font-weight: 600;
  min-height: $control-height;
  padding: $rhythm $rhythm * 4;
  background: $color--primary;
  border-radius: $radius;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $color--white;
  width: auto;
  cursor: pointer;
  @include transition--primary;

  @include media('>lg') {
    min-width: 120px;
  }

  @include media('<=xs') {
    padding: $rhythm $rhythm * 3;
  }

  &:hover {
    background: darken($color--primary, 10);
  }

  &.large {
    padding: $rhythm * 2 $rhythm * 4;
    font-size: 15px;
    min-height: 64px;
  }

  &.block {
    display: flex;
    width: 100%;
  }

  &.xsmall {
    padding: $rhythm / 2.5 $rhythm * 1.5;
    font-size: 12px;
    min-height: 30px;
    min-width: 0;
  }

  &.small {
    padding: $rhythm / 2 $rhythm * 2;
    font-size: 13px;
    min-height: 40px;

    @include media('>lg') {
      min-width: 80px;
    }
  }

  &.outline {
    border: 1px solid #eee !important;
    color: $color--primary;
    background: #fff !important;

    &:hover {
      border: 1px solid $color--primary !important;
      color: #fff;
      background: $color--primary !important;
    }
  }

  &.link {
    background-color: transparent;

    &.link {
      color: $color--primary;
    }
  }

  &.error {
    background: $color--red;

    &:hover {
      background: darken($color--red, 10);
    }

    &.outline {
      color: $color--red;

      &:hover {
        border-color: $color--red !important;
        color: #fff;
        background: $color--red !important;
      }
    }

    &.link {
      color: $color--red;
    }
  }

  &.success {
    background: $color--green;

    &:hover {
      background: darken($color--green, 10);
    }

    &.outline {
      color: $color--green;

      &:hover {
        border-color: $color--green !important;
        color: #fff;
        background: $color--green !important;
      }
    }

    &.link {
      color: $color--green;
    }
  }

  &.danger {
    background: $color--red;

    &:hover {
      background: darken($color--red, 10);
    }

    &.outline {
      color: $color--red;

      &:hover {
        border-color: $color--red !important;
        color: #fff;
        background: $color--red !important;
      }
    }

    &.link {
      color: $color--red;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: $color--grey--dark;
  }

  & + & {
    margin-left: $rhythm;
  }
}
</style>
