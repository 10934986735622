import api from '@/api'
import Vue from 'vue'
import { fields } from '@/helpers/forms'
import i18n from '@/setup/i18n'

const state = {
  currentState: null,
  subLocationsState: [],
  notifications: null,
  formsToApprove: [],
  skiRunStatusOptions: fields.skiRunStatus,
  skiLiftStatusOptions: fields.skiLiftStatus,
  skiLiftTrafficOptions: fields.skiLiftTraffic,
  skiRunTrafficOptions: fields.skiRunTraffic,
  windDirectionOptions: fields.windDirection,
  timeOptions: fields.time,
  snowTypeOptions: fields.snowType,
  avalancheDangerOptions: fields.avalancheDanger
}

const mutations = {
  SET_FORMS_TO_APPROVE(state, payload) {
    state.formsToApprove = payload
  },
  SET_CURRENT_STATE(state, payload) {
    state.currentState = payload
  },
  SET_SUB_LOCATIONS_STATE(state, payload) {
    state.subLocationsState = payload
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  }
}

const actions = {
  createForm({ commit, rootState }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve) => {
      delete payload.data.locationId
      delete payload.data.locationName
      delete payload.data.locationType
      delete payload.data.id
      delete payload.data.skiStationName

      api.forms
        .createLocationForm(payload.workspaceId, payload.data)
        .then((response) => {
          commit('SET_CURRENT_STATE', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('forms.sendSuccess') })
          resolve()
        })
        .catch((error) => {
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('forms.sendError')
          })
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
        })
    })
  },
  fetchCurrentWorkspaceState({ commit, rootState }) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve) => {
      api.forms
        .getLocationsForm(rootState.workspaces.currentWorkspace.id)
        .then((response) => {
          commit('SET_CURRENT_STATE', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          resolve(response.data)
        })
        .catch(() => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
        })
    })
  },
  fetchSubLocationsState({ commit, rootState }) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve) => {
      api.forms
        .getSubLocationsForm(rootState.workspaces.currentWorkspace.id)
        .then((response) => {
          commit('SET_SUB_LOCATIONS_STATE', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          resolve(response.data)
        })
        .catch(() => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
        })
    })
  },
  fetchFormsToApprove({ commit, rootState }) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve) => {
      api.forms
        .getFormsToApprove(rootState.workspaces.currentWorkspace.id)
        .then((response) => {
          commit('SET_FORMS_TO_APPROVE', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          resolve(response.data)
        })
        .catch(() => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
        })
    })
  },
  fetchFormToFill({ commit, rootState }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      api.forms
        .getFormToFill(payload)
        .then((response) => {
          commit('appshell/endSectionLoading', null, { root: true })
          resolve(response.data)
        })
        .catch(() => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
          reject()
        })
    })
  },
  sendFormToFill({ commit, rootState }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      api.forms
        .sendFormToFill(payload.token, payload.locationId, payload.data)
        .then(() => {
          commit('appshell/endSectionLoading', null, { root: true })
          resolve()
        })
        .catch(() => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
          reject()
        })
    })
  },
  approveForm({ commit, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      api.forms
        .approveForm(payload)
        .then(() => {
          dispatch('fetchFormsToApprove')
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('forms.approveSuccess') })
          resolve()
        })
        .catch((error) => {
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('forms.approveSuccess')
          })
          reject()
        })
    })
  },
  rejectForm({ commit, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      api.forms
        .rejectForm(payload)
        .then(() => {
          dispatch('fetchFormsToApprove')
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'warning', message: i18n.t('forms.rejectSuccess') })
          resolve()
        })
        .catch((error) => {
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('forms.rejectError')
          })
          reject()
        })
    })
  },

  getFormHistory({ commit, rootState }) {
    return new Promise((resolve) => {
      api.forms.getFormHistory(rootState.workspaces.currentWorkspace.id).then((response) => resolve(response.data))
    })
  }
}

const getters = {}

export default { namespaced: true, state, getters, actions, mutations }
