<template lang="pug">
components(:is="portal ? 'portal' : 'transition'", :to="portal ? 'popups' : null", name="fade")
  .bt-popup(
    v-if="showPopup",
    :class="[`bt-popup--${type}`, `bt-popup--direction-${direction}`, `bt-popup--align-${align}`]",
    ref="root",
    v-click-outside="close",
    :style="positionStyles"
  )
    .bt-popup__arrow
    .bt-popup__body(:class="{ 'bt-popup__body--dropdown': isDropdown }")
      // transition(name="fade", mode="out-in")
      slot
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

let uuid = 0
export default {
  name: 'BtPopup',
  props: {
    direction: {
      type: String,
      default: 'right'
    },
    type: {
      type: String,
      default: 'default'
    },
    align: {
      type: String,
      default: 'bottom'
    },
    position: {
      type: String,
      default: 'fixed'
    },
    isDropdown: {
      default: false,
      type: Boolean
    },
    portal: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      positionStyles: {}
    }
  },
  computed: {
    ...mapGetters('appshell', ['isPopupOpen']),
    showPopup() {
      return this.isPopupOpen === this.id
    }
  },
  beforeCreate() {
    this.id = `bt-popup-${uuid.toString()}`
    uuid += 1
  },
  created() {
    this.$on('open', this.open)
    this.$on('toggle', this.toggle)
    this.$on('close', this.close, false)
  },
  methods: {
    ...mapMutations('appshell', ['TOGGLE_POPUP']),
    calculatePosition(el) {
      this.$nextTick(() => {
        const viewportOffset = el.getBoundingClientRect()

        if (this.portal) {
          if (this.align === 'center') {
            this.positionStyles.top = viewportOffset.top + viewportOffset.height * 0.5 + 'px'
            if (this.direction === 'left') this.positionStyles.right = window.innerWidth - viewportOffset.left + 'px'
            else if (this.direction === 'right') this.positionStyles.right = viewportOffset.right + 'px'
          } else if (this.align === 'bottom') {
            this.positionStyles.top = viewportOffset.top + viewportOffset.height + 'px'
            if (this.direction === 'left') this.positionStyles.right = window.innerWidth - viewportOffset.left + 'px'
            else if (this.direction === 'right') this.positionStyles.right = window.innerWidth - viewportOffset.left - viewportOffset.width + 'px'
            else if (this.direction === 'center') this.positionStyles.right = window.innerWidth - viewportOffset.left - viewportOffset.width * 0.5 + 'px'
          }
        } else {
          if (this.align === 'center') {
            this.positionStyles.top = viewportOffset.height * 0.5 + 'px'
            if (this.direction === 'left') this.positionStyles.right = viewportOffset.width + 'px'
            else if (this.direction === 'right') this.positionStyles.right = viewportOffset.width + 'px'
          } else if (this.align === 'bottom') {
            this.positionStyles.top = viewportOffset.height + 'px'
            if (this.direction === 'left') this.positionStyles.right = window.innerWidth - viewportOffset.left + 'px'
            else if (this.direction === 'right') this.positionStyles.right = 0
            else if (this.direction === 'center') this.positionStyles.right = window.innerWidth - viewportOffset.left - viewportOffset.width * 0.5 + 'px'
          }
        }
      })
    },
    close(emit) {
      this.TOGGLE_POPUP(null)
    },
    toggle(e) {
      if (e) {
        this.calculatePosition(e.target || e)
        this.TOGGLE_POPUP(this.id)
      }
    }
  }
}
</script>

<style lang="scss">
.bt-popup {
  $block: &;

  @include reset;

  position: absolute;
  width: auto;
  z-index: 300;
  color: $color--global;
  pointer-events: auto;
  // visibility: hidden;
  // opacity: 0;

  &--direction-top {
    // bottom: calc(100% + #{$rhythm});
  }

  &--direction-right {
    // left: calc(100% + #{$rhythm});
  }

  &--align-bottom {
    // top: calc(100% + #{$rhythm});
  }

  &--direction-left {
    margin-right: 1rem;
  }

  &--direction-center {
    margin-top: 1rem;
    transform: translate(50%, 0);
  }

  &--align-bottom {
    margin-top: 1rem;
  }

  &--align-center {
    &#{$block}--direction-top,
    &#{$block}--direction-bottom {
      left: 50%;
      transform: translate(-50%, 0);
    }

    &#{$block}--direction-right,
    &#{$block}--direction-left {
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &__body {
    @include reset;
    @include bright-slab;

    border-radius: $radius;
    padding: $rhythm;
    box-shadow: $shadow--lg;
    position: relative;
    // max-height: 100%;
    // overflow: auto;

    @at-root #{$block}--options > & {
      min-width: 145px;
    }

    @include media('<=sm') {
      // right: 100%;
    }

    &--no-scrolling {
      overflow: hidden;
    }

    &--dropdown {
      padding: $rhythm;
    }
  }

  &__arrow {
    @include reset;

    position: absolute;
    width: 0;
    height: 0;
    z-index: 4;
    border-style: solid;

    @at-root #{$block}--direction-top > & {
      top: 100%;
      border-width: 6px 6px 0 6px;
      border-color: #fff transparent transparent transparent;
    }

    @at-root #{$block}--direction-right > & {
      right: 100%;
      border-width: 6px 6px 6px 0;
      border-color: transparent #fff transparent transparent;
    }

    @at-root #{$block}--direction-bottom > & {
      bottom: 100%;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent #fff transparent;
    }

    @at-root #{$block}--direction-left > & {
      left: 100%;
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent #fff;
    }

    @at-root #{$block}--align-bottom > & {
      border-width: 6px 6px 6px;
      border-color: transparent transparent #fff transparent;
    }

    @at-root #{$block}--direction-center > & {
      left: calc(50% - 6px);
    }

    @at-root #{$block}--direction-right > & {
      right: $rhythm * 2;
    }

    @at-root #{$block}--align-top > & {
      top: $rhythm * 2;
    }

    @at-root #{$block}--align-right > & {
      right: $rhythm * 2;
    }

    @at-root #{$block}--align-bottom > & {
      bottom: 100%;
    }

    @at-root #{$block}--align-left > & {
      left: $rhythm * 2;
    }

    @at-root #{$block}--align-center#{$block}--direction-right > &,
      #{$block}--align-center#{$block}--direction-left > & {
      top: 50%;
      transform: translate(0, -50%);
    }

    @at-root #{$block}--align-center#{$block}--direction-top > &,
      #{$block}--align-center#{$block}--direction-bottom > & {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &--static {
    position: relative;
    top: 0;
    left: 0;
  }

  &--extensible {
    width: 100%;
  }

  &--device {
    width: 400px;
  }
}
</style>
