<template lang="pug">
// Basic Checkbox - uses v-model

.bt-checkbox(@click="$refs.input.click()", :class="{ 'bt-checkbox--disabled': disabled, 'bt-checkbox--custom': custom }")
  input.bt-checkbox__hidden(
    ref="input",
    type="checkbox",
    @change="change",
    :checked="typeof checked === typeof true ? checked : checked.indexOf(value) !== -1",
    :value="value",
    :disabled="disabled"
  )

  span.bt-checkbox__indicator(:class="{ 'bt-checkbox__indicator--disabled': disabled }")
    svg.icon.bt-checkbox__mock(
      :class="{ 'bt-checkbox__mock--disabled': disabled }",
      v-html="require('@fortawesome/fontawesome-free/svgs/solid/check.svg?inline')"
    )

  span.bt-checkbox__label(v-if="label", :class="{ 'bt-checkbox__label--disabled': disabled }") {{ label }}
  span.bt-checkbox__label(v-if="hasLabelSlot", :class="{ 'bt-checkbox__label--disabled': disabled }")
    slot
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: [Boolean, Array],
      required: true
    },
    value: {
      type: [String, Number, Object],
      default: null
    },
    label: {
      type: [String, Boolean],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    custom: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasLabelSlot() {
      return !!this.$slots.default // !!this.$slots['label']
    }
  },
  methods: {
    change($event) {
      if (typeof this.checked === typeof true) {
        this.$emit('change', $event.target.checked)
      } else {
        if ($event.target.checked) {
          this.checked.push(this.value)
        } else {
          this.checked.splice(
            this.checked.findIndex((i) => i === this.value),
            1
          )
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bt-checkbox {
  $block: &;

  @include reset;

  width: auto;
  transition: 250ms;
  margin-right: $rhythm * 0.5;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  &--disabled {
    cursor: not-allowed;
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  @at-root #{$block}__item--has-error & {
    background-color: $color--red--sm;
  }

  &--inline {
    display: inline-block;

    & + & {
      margin-left: 1.5rem;
      margin-bottom: 0;
    }
  }

  &__hidden {
    @include reset;

    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    pointer-events: none;
    opacity: 0;
  }

  &--push-right {
    margin-left: auto;
  }
  display: flex;

  #{$block}__indicator {
    @include reset;
    position: relative;
    width: $rhythm * 2.75;
    height: $rhythm * 2.75;
    border-radius: $radius--sm;
    cursor: pointer;
    background: $color--white;
    border: 1px solid $color--grey--light;
    flex: 0 0 auto;
    @include transition--primary;
    cursor: pointer !important;

    #{$block}__mock {
      position: absolute;
      height: 0.8em;
      width: 0.8em;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      fill: $color--primary;
      opacity: 0;
      @include transition--primary;
    }

    &--disabled {
      background: $color--grey--extra-light;
      cursor: not-allowed;
    }
  }
  @at-root #{$block}__hidden:checked + #{$block}__indicator #{$block}__mock {
    opacity: 1;
  }

  #{$block}__label {
    font-weight: 600;
    line-height: 22px;
    padding-bottom: 0;
    margin-left: 0.5rem;
    display: block;

    &--disabled {
      color: $color--muted;
    }
  }

  &--custom {
    #{$block}__indicator {
      border-radius: 50%;
      border: 2px solid $color--line;
      width: $rhythm * 3;
      height: $rhythm * 3;

      #{$block}__mock {
        height: 0.7em;
        width: 0.7em;
      }
    }

    @at-root #{$block}--custom #{$block}__hidden:checked + #{$block}__indicator {
      border: 2px solid $color--white;
      background-color: $color--primary;
      box-shadow: $shadow;

      #{$block}__mock {
        fill: $color--white;
      }
    }
  }
}
</style>
