import axios from 'axios'

export default {
  auth: (data) => axios.post('api/v1/auth/signin', data),
  fetchUser: () => axios.get('/api/v1/users/profile'),
  editProfile: (data) =>
    axios.put(`/api/v1/users/profile`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }),
  validatePasswordResetTokenAndChangeNewPassword: (username, token, data) => axios.post(`/api/v1/user-profile/${username}/password/${token}`, data),
  checkPassword: (data) => axios.post('/api/v1/user-profile/password/check', data),
  editPassword: (data) => axios.put(`/api/v1/users/change-password`, data),
  resetPassword: (email) => axios.post(`/api/v1/users/remind-password?email=${encodeURIComponent(email)}`),
  setNewPassword: (data, token) => axios.put(`/api/v1/users/remind-password?token=${token}`, data),
  test: () => axios.get('/api/v1/utility/exception')
}
