import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import store from '@/store'

Vue.use(VueAxios, axios)

axios.defaults.baseURL = process.env.VUE_APP_API

axios.interceptors.request.use(
  function (config) {
    store.commit('appshell/startLoading')
    return config
  },
  function (error) {
    store.commit('appshell/startLoading')
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    store.commit('appshell/endLoading')
    return response
  },
  function (error) {
    store.commit('appshell/endLoading')
    console.error('API', error.message)
    if (error.response && error.response.config.url.indexOf('/refresh') > -1) {
      Vue.auth.logout()
    }
    return Promise.reject(error)
  }
)
