import Vue from 'vue'
import Snotify, { SnotifyPosition } from 'vue-snotify'

Vue.use(Snotify, {
  global: {
    newOnTop: false,
    maxOnScreen: 4
  },
  toast: {
    timeout: 2000,
    animation: { enter: 'fadeIn', exit: 'fadeOut', time: 300 },
    icon: false,
    pauseOnHover: false,
    position: SnotifyPosition.rightBottom
  }
})
