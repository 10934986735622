import api from '@/api'
import i18n from '@/setup/i18n'

const state = {
  users: [],
  userForm: {
    createUserRequest: {
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    },
    authority: null,
    places: {}
  },
  availableAuthorities: [],
  currentWorkspaceAuthorities: [],
  userWorkspaces: [],
  newUserFormStep: 1,
  showAddUserModal: false,
  showSendMessage: false,
  showAddUserConfirmationModal: false,
  showLockAccessUserModal: false,
  showUnlockAccessUserModal: false,
  showResetUserPassModal: false,
  showRemoveUserModal: false,
  notifications: null
}

const mutations = {
  SET_USERS(state, payload) {
    state.users = payload
  },

  REMOVE_USER(state, payload) {
    state.users = state.users.filter((user) => user.id !== payload)
  },

  SET_USER_DETAILS(state, payload) {
    const keys = Object.keys(state.userForm.createUserRequest)
    const payloadKey = Object.keys(payload)[0]

    for (const i in keys) {
      const item = keys[i]
      if (keys[i] === payloadKey) state.userForm.createUserRequest[item] = payload[item]
    }
  },

  SET_AVAILABLE_AUTHORITIES(state, payload) {
    state.availableAuthorities = payload
  },

  SET_USER_WORKSPACES(state, payload) {
    state.userWorkspaces = payload
  },

  SET_NEW_USER_FORM_STEP(state, payload) {
    state.newUserFormStep = payload
  },

  SET_USER_FORM_PLACES(state, payload) {
    state.userForm.places = payload
  },

  SET_USER_FORM_AUTHORITIES(state, payload) {
    state.userForm.authority = payload
  },

  CLEAR_USER_FORM(state) {
    state.userForm = {
      createUserRequest: {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      },
      authority: null,
      places: {}
    }
  },

  TOGGLE_LOCK_ACCESS_USER_MODAL(state, payload) {
    state.showLockAccessUserModal = payload
  },

  TOGGLE_UNLOCK_ACCESS_USER_MODAL(state, payload) {
    state.showUnlockAccessUserModal = payload
  },

  TOGGLE_RESET_USER_PASS_MODAL(state, payload) {
    state.showResetUserPassModal = payload
  },

  TOGGLE_REMOVE_USER_MODAL(state, payload) {
    state.showRemoveUserModal = payload
  },

  TOGGLE_ADD_USER_MODAL(state, payload) {
    state.showAddUserModal = payload
  },

  TOGGLE_SEND_MESSAGE_MODAL(state, payload) {
    state.showSendMessage = payload
  },

  TOGGLE_ADD_USER_CONFIRMATION_MODAL(state, payload) {
    state.showAddUserConfirmationModal = payload
  },

  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  }
}

const actions = {
  sendMessage({ commit, dispatch }, payload) {
    api.messages
      .sendMessage(payload)
      .then((response) => {
        console.log('sendMessage: ', response.data)

        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: 'Wiadomość Wysłana'
        })
      })
      .catch((error) => {
        console.log('sendMessage: ', error)
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : 'Błąd wysłania Wiadomości'
        })
      })
  },

  fetchUsersInWorkspace({ commit, rootState }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve) => {
      api.users
        .getUsersInPlace(rootState.workspaces.currentWorkspace.id)
        .then((response) => {
          commit('SET_USERS', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          resolve(response.data)
        })
        .catch((error) => {
          console.log('fetchUsers: ', error)
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
        })
    })
  },

  addUserToCurrentWorkspace({ commit, dispatch, rootState }, payload) {
    return new Promise((resolve) => {
      api.users
        .createUserInPlace(rootState.workspaces.currentWorkspace.id, payload)
        .then((response) => {
          if (rootState.workspaces.currentWorkspace) dispatch('fetchUsersInWorkspace')
          else dispatch('fetchUsers')

          commit('SET_NOTIFICATIONS', {
            type: 'success',
            message: i18n.t('users.addSuccess')
          })

          resolve()
        })
        .catch((error) => {
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('users.addError')
          })
          console.log('addFolder: ', error)
        })
    })
  },

  updateUser({ commit, dispatch }, payload) {
    if (!payload) return

    const formData = new FormData()

    formData.append('request', new Blob([JSON.stringify(payload.data)], { type: 'application/json' }))
    formData.append('avatar', null)

    api.users
      .editProfile(payload.email, formData)
      .then(() => {
        dispatch('fetchUsersInWorkspace')
        commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('users.updateSuccess') })
      })
      .catch((error) => {
        console.log('updateUser: ', error.message)
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('users.updateError')
        })
      })
  },

  removeUser({ commit, dispatch }, payload) {
    api.users
      .removeUser(payload.email)
      .then((response) => {
        console.log('removeUser: ', response.data)
        commit('REMOVE_USER', response.data)
        commit('TOGGLE_REMOVE_USER_MODAL', false)
        dispatch('fetchUsersInWorkspace')
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: i18n.t('users.removeSuccess')
        })
      })
      .catch((error) => {
        console.log('removeUser: ', error)
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('users.removeError')
        })
      })
  },

  resetUsersPassword({ commit, dispatch }, payload) {
    api.users
      .resetPassword(payload.email)
      .then((response) => {
        commit('REMOVE_USER', response.data)
        commit('TOGGLE_RESET_USER_PASS_MODAL', false)
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: i18n.t('users.resetPassword.success')
        })
      })
      .catch((error) => {
        console.error('resetUsersPassword: ', error)
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('users.resetPassword.error')
        })
      })
  }
}

const getters = {
  users(state) {
    return state.users
  },
  userForm(state) {
    return state.userForm
  },
  newUserFormStep(state) {
    return state.newUserFormStep
  },
  availableAuthorities(state) {
    return state.availableAuthorities
  },
  roles(state) {
    return state.roles
  },
  userWorkspaces(state) {
    return state.userWorkspaces
  },
  currentWorkspaceAuthorities(state) {
    return state.currentWorkspaceAuthorities
  },
  showAddUserModal(state) {
    return state.showAddUserModal
  },

  showSendMessage(state) {
    return state.showSendMessage
  },
  showAddUserConfirmationModal(state) {
    return state.showAddUserConfirmationModal
  },
  showLockAccessUserModal(state) {
    return state.showLockAccessUserModal
  },
  showUnlockAccessUserModal(state) {
    return state.showUnlockAccessUserModal
  },
  showResetUserPassModal(state) {
    return state.showResetUserPassModal
  },
  showRemoveUserModal(state) {
    return state.showRemoveUserModal
  },
  notifications(state) {
    return state.notifications
  }
}

export default { namespaced: true, state, getters, actions, mutations }
