// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

// Third-party dependencies
import Vue from 'vue'
import VueMoment from 'vue-moment'
import Datetime from 'vue-datetime'
import { Settings } from 'luxon'
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons'
import 'vue-datetime/dist/vue-datetime.css'
import PortalVue from 'portal-vue'
import VueClazyLoad from 'vue-clazy-load'
import VueScreen from 'vue-screen'

// Application shell
import App from './App'

// Setup is to decouple integrations from configurations
import './setup/router'
import './setup/axios'
import './setup/validation'
import './setup/lodash'
import './setup/notifications'
import './setup/polyfills'
import './setup/directives'
import './setup/auth'
import i18n from './setup/i18n'

// Services
import router from './router'
import store from './store'
import api from './api'

// Mixins
import './mixins/global/canUserDoIt'
import './mixins/global/getImageUrl'

// Global CSS styles
import '@/assets/scss/style.scss'

// Global components
import ButtonLoader from '@/components/ButtonLoader'
import { Container, Row, Column } from '@/components/BtGrid/index.js'
import BtSelect from '@/components/BtSelect/index.js'
import BtDialog from '@/components/BtDialog/index.js'
import BtPopup from '@/components/BtPopup/index.js'
import BtButton from '@/components/BtButton/index.js'

Vue.use(PortalVue)
Settings.defaultLocale = 'en'
Vue.use(VueMoment)
Vue.use(Datetime)
Vue.use(VueClazyLoad)
Vue.use(VueScreen)

Vue.component('icon', Icon)
Vue.component('button-loader', ButtonLoader)
Vue.component('bt-container', Container)
Vue.component('bt-row', Row)
Vue.component('bt-col', Column)
Vue.component('bt-select', BtSelect)
Vue.component('bt-dialog', BtDialog)
Vue.component('bt-popup', BtPopup)
Vue.component('bt-button', BtButton)

Vue.prototype.$api = api
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  components: { App },
  template: '<App/>'
})

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

window.addEventListener('resize', () => {
  vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

document.title = process.env.VUE_APP_TITLE
