import media from './media'
import account from './account'
import folders from './folders'
import users from './users'
import workspaces from './workspaces'
import forms from './forms'
import announcements from './announcements'
import widgets from './widgets'
import weatherStations from './weatherStations'
import messages from './messages'

export default {
  announcements,
  media,
  account,
  folders,
  users,
  workspaces,
  forms,
  widgets,
  weatherStations,
  messages
}
