import api from '@/api'
import i18n from '@/setup/i18n'
import { fields } from '@/helpers/widgets'

const state = {
  notifications: null,
  showWidgetsCreator: false,
  widgets: null,
  widgetToEdit: null,
  availableStations: [],
  widgetRanges: fields.widgetRanges,
  weatherFields: fields.weatherFields,
  operatorStationFields: fields.operatorStationFields,
  skiRunFields: fields.skiRunFields,
  skiLiftFields: fields.skiLiftFields,
  announcementFields: fields.announcementFields,
  widgetTypes: fields.widgetTypes,
  widgetThemes: fields.widgetThemes,
  cornerTypes: fields.cornerTypes,
  backgroundTransparentTypes: fields.backgroundTransparentTypes,
  mainColors: ['#0080E4', '#6457a6', '#e55934', '#fa7921', '#a3b8c2', '#66b31b', '#d1a488'],
  backgroundColors: ['#341671', '#6457a6', '#996fd6', '#b59ce0', '#d0c9ea']
}

const mutations = {
  SET_WIDGETS_CREATOR(state, payload) {
    state.showWidgetsCreator = payload
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },
  SET_AVAILABLE_STATIONS(state, payload) {
    state.availableStations = payload
  },
  SET_WIDGETS(state, payload) {
    state.widgets = payload
  },
  SET_WIDGET_TO_EDIT(state, payload) {
    state.widgetToEdit = payload
  }
}

const actions = {
  getAvailableStations({ commit }) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve) => {
      api.widgets
        .getAvailableStations()
        .then((response) => {
          commit('SET_AVAILABLE_STATIONS', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          resolve(response.data)
        })
        .catch(() => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
        })
    })
  },
  fetchAllWidgets({ commit }) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve) => {
      api.widgets
        .getAllWidgets()
        .then((response) => {
          commit('SET_WIDGETS', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          resolve(response.data)
        })
        .catch(() => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
        })
    })
  },
  createWidget({ commit, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      api.widgets
        .createWidget(payload)
        .then((response) => {
          dispatch('fetchAllWidgets')
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('widgets.create.success') })
          resolve(response)
        })
        .catch((error) => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('widgets.create.error')
          })
          reject()
        })
    })
  },
  updateWidget({ commit, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      api.widgets
        .updateWidget(payload.id, payload)
        .then((response) => {
          dispatch('fetchAllWidgets')
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('widgets.update.success') })
          resolve(response)
        })
        .catch((error) => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('widgets.update.error')
          })
          reject()
        })
    })
  },
  deleteWidget({ commit, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      api.widgets
        .deleteWidget(payload)
        .then(() => {
          dispatch('fetchAllWidgets')
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('widget.removeWidget.success') })
          resolve()
        })
        .catch((error) => {
          // commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('widgets.removeWidget.error')
          })
          reject()
        })
    })
  },

  activateWidgetById({ commit, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      api.widgets
        .activateWidget(payload)
        .then(() => {
          dispatch('fetchAllWidgets')
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('widget.activateWidget.success') })
          resolve()
        })
        .catch((error) => {
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('widgets.activateWidget.error')
          })
          reject()
        })
    })
  },

  deactivateWidgetById({ commit, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      api.widgets
        .deactivateWidget(payload)
        .then(() => {
          dispatch('fetchAllWidgets')
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('widget.deactivateWidget.success') })
          resolve()
        })
        .catch((error) => {
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('widgets.deactivateWidget.error')
          })
          reject()
        })
    })
  },

  duplicateWidgetById({ commit, dispatch }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      api.widgets
        .duplicateWidget(payload)
        .then(() => {
          dispatch('fetchAllWidgets')
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('widget.duplicateWidget.success') })
          resolve()
        })
        .catch((error) => {
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('widgets.duplicateWidget.error')
          })
          reject()
        })
    })
  }
}

const getters = {}

export default { namespaced: true, state, getters, actions, mutations }
