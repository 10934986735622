import axios from 'axios'

export default {
  getPlaceStructure: () => axios.get('/api/v1/location/structure'),
  getPlaceStructureByQuery: (query) => axios.get(`/api/v1/location/structure?query=${query}`),
  getPlace: (locationId) => axios.get(`/api/v1/location/${locationId}/details`),
  editPlaceStructure: (data) => axios.put('/api/v1/location-structure', data),
  addNewPlace: (data) => axios.post('/api/v1/location', data),
  addNewSubPlace: (parentId, data) => {
    const formData = new FormData()
    formData.append('logo', null)
    formData.append('request', new Blob([JSON.stringify(data)], { type: 'application/json' }))

    return axios.post(`/api/v1/location/${parentId}`, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  updatePlace: (locationId, data) => {
    const formData = new FormData()
    formData.append('logo', null)
    formData.append('request', new Blob([JSON.stringify(data)], { type: 'application/json' }))

    return axios.put(`/api/v1/location/${locationId}`, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  updateMultiplePlaces: (data) => axios.put(`/api/v1/location/`, data),
  deletePlace: (locationId) => axios.delete(`/api/v1/location/${locationId}`),
  uploadLogo: (data) => axios.post('/api/v1/location/logo', data),
  updateOrder: (locationId, data) => axios.put(`/api/v1/location/${locationId}/structure-order?locationsOrder=${data}`)
}
