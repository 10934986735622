import axios from 'axios'

export default {
  getAnnouncements: (locationId) => axios.get(`/api/v1/location/${locationId}/announcements`),
  createAnnouncement: (locationId, data) => axios.post(`/api/v1/location/${locationId}/announcements`, data),
  updateAnnouncement: (locationId, announcementId, data) =>
    axios.put(`/api/v1/location/${locationId}/announcements/${announcementId}`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }),
  archiveAnnouncement: (locationId, announcementId, data) => axios.put(`/api/v1/location/${locationId}/announcements/${announcementId}/archive`, data),
  deleteAnnouncement: (locationId, announcementId, data) => axios.delete(`/api/v1/location/${locationId}/announcements/${announcementId}`, data),
  getLanguages: (locationId) => axios.get(`/api/v1/location/${locationId}/announcements/metadata/language`)
}
