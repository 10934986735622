<template lang="pug">
.bt-select(:class="classes")
  label.bt-select__label(:class="{ 'bt-select__label--inline': labelInline }", :for="id", v-show="!hideLabel", v-if="label")
    | {{ label }} <slot name="label"></slot>
  .bt-select__toggle(:id="id", :class="{ 'bt-select__toggle--large': size === 'large' }", @click.prevent.stop="toggle", v-click-outside="close")
    span.bt-select__toggle__pre__icon(v-if="showIcon")
      slot(name="preIcon")
    .bt-select__toggle__value(:class="{ 'bt-select__toggle__value--icon': showIcon }")
      template(v-if="!this.$slots.default") {{ computedLabel.label }}
        span.bt-select__addition(v-if="computedLabel.addition") {{ computedLabel.addition }}
        .bt-select__status-icon(
          v-if="isValidStatus",
          :class="{ 'bt-select__status-icon--open': value === 'OPEN', 'bt-select__status-icon--close': value === 'CLOSED', 'bt-select__status-icon--in-preparation': value === 'IN_PREPARATION', 'bt-select__status-icon--temporarily-closed': value === 'TEMPORARILY_CLOSED' }"
        )
      slot(v-else)
    svg.bt-select__toggle__icon(v-html="require('@/assets/img/icons/toggle.svg?inline')")
  bt-dialog.bt-select__dropdown(
    v-if="isMobile && isOpen",
    name="btSelectDropdownMobile",
    :hideCloseButton="true",
    :noCancel="true",
    @close="close",
    type="bottomSheet"
  )
    h2(:style="{ textAlign: 'center', marginBottom: '1rem' }") {{ title }}
    .dialog-content(v-if="multi")
      bt-select-dropdown-item.bt-select__dropdown__list__item--mobile(
        :class="{ 'bt-select__dropdown__list__item--mobile--checked': value.indexOf(option.id) !== -1 }",
        v-for="(option, index) in computedOptions",
        :key="'checkbox-' + index",
        :option="option",
        :value="value",
        :multi="multi",
        :optionAdapter="optionAdapter",
        @select="(item) => change(item)",
        :checkable="multi"
      ) {{ option.label }}
      bt-button(@click="close", :style="{ margin: '0 auto', minWidth: '226px', display: 'block' }") {{ $t('ui.select') }}
    .dialog-content(v-else)
      bt-radio-extended(
        v-for="(option, index) in computedOptions",
        :key="'radio-' + index",
        name="btSelectDropdownItemMobile",
        :checked="value",
        :value="option.id",
        @change="() => change(option)"
      )
        span.bt-select__dropdown__list__item__label--mobile {{ option.label }}
          .bt-select__status-icon(
            v-if="option.status",
            :class="{ 'bt-select__status-icon--open': option.status === 'OPEN', 'bt-select__status-icon--close': option.status === 'CLOSED', 'bt-select__status-icon--in-preparation': option.status === 'IN_PREPARATION', 'bt-select__status-icon--temporarily-closed': option.status === 'TEMPORARILY_CLOSED' }"
          )
        span.bt-select__dropdown__list__item__addition--mobile(v-if="option.addition") {{ option.addition }}
  .bt-select__dropdown(:class="{ 'bt-select__dropdown--large': size === 'large', 'bt-select__dropdown--label': label }")
    slide-up-down(
      :active="!isMobile && isOpen",
      :duration="300",
      @open-start="isTransitioning = true",
      @open-end="isTransitioning = false",
      @close-start="isTransitioning = true",
      @close-end="isTransitioning = false"
    )
      .bt-select__dropdown__inner
        ul.bt-select__dropdown__list
          bt-select-dropdown-item(
            v-for="(option, index) in computedOptions",
            :key="index",
            :option="option",
            :value="value",
            :multi="multi",
            :optionAdapter="optionAdapter",
            :active="value === option.id",
            @select="(item) => change(item)",
            :checkable="multi"
          ) {{ option.label }}
            .bt-select__status-icon(
              v-if="option.status",
              :class="{ 'bt-select__status-icon--open': option.status === 'OPEN', 'bt-select__status-icon--close': option.status === 'CLOSED', 'bt-select__status-icon--in-preparation': option.status === 'IN_PREPARATION', 'bt-select__status-icon--temporarily-closed': option.status === 'TEMPORARILY_CLOSED' }"
            )
        template(v-if="action")
          hr.bt-select__dropdown__sep
          ul.bt-select__dropdown__list
            li.bt-select__dropdown__list__item(@click.stop="$emit('action')") {{ action }}
</template>

<script>
import SlideUpDown from 'vue-slide-up-down'
import { formFields } from '@/mixins/form'
import BtCheckbox from '../BtCheckbox/BtCheckbox'

import { merge } from 'lodash'

export default {
  name: 'BtSelect',
  components: {
    BtCheckbox,
    SlideUpDown,
    BtRadioExtended: () => import('../BtRadio/BtRadioExtended'),
    BtDialog: () => import('../BtDialog/BtDialog'),
    BtSelectDropdownItem: () => import('./partials/BtSelectDropdownItem')
  },
  mixins: [formFields],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    /**
     * Select value
     */
    value: {
      type: [String, Number, Object, Array],
      required: false,
      default: null | []
    },
    /**
     * Options list
     */
    options: {
      type: Array,
      default: () => [],
      required: true
    },
    /**
     * Options adapter which transform the options list and value
     */
    optionAdapter: {
      type: Function,
      default: (item) => ({
        id: item,
        label: item,
        item
      })
    },
    /**
     * Optional action
     */
    action: {
      type: [String, Boolean],
      default: false
    },
    /**
     * Set to true if inline
     */
    inline: {
      type: Boolean,
      default: false
    },
    /**
     * Set to true to remove border
     */
    white: {
      type: Boolean
    },
    /**
     * Set to true to make it strrrrong
     */
    strong: {
      type: Boolean
    },
    /**
     * Set to true to make it grey
     */
    grey: {
      type: Boolean
    },
    /**
     * Use it to change size of the componenet
     */
    size: {
      type: String,
      default: 'medium',
      validate: (val) => ['medium', 'large'].indexOf(val) > -1
    },
    /**
     * Use it to change placeholder
     */
    placeholder: {
      type: String,
      default: `...`
    },
    /**
     * Use it to disable selection
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Use to decrease width
     */
    thin: {
      type: Boolean,
      default: false
    },
    /**
     * Use it to multi select
     */
    multi: {
      type: Boolean,
      default: false
    },
    /**
     * Use with the subitems
     */
    nested: {
      type: Boolean,
      default: false
    },
    /**
     * Use it as dialog title
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Long multiselect labels
     */
    longMultiLabels: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      container: [],
      isOpen: false,
      isTransitioning: false,
      isValidStatus: this.options.every((option) => option.status)
    }
  },
  computed: {
    classes() {
      return {
        'bt-select--disabled': this.disabled,
        'bt-select--inline': this.inline,
        'bt-select--white': this.white,
        'bt-select--strong': this.strong,
        'bt-select--grey': this.grey,
        'bt-select--thin': this.thin,
        'bt-select--open': this.isOpen,
        'bt-select--transitioning': this.isTransitioning
      }
    },
    computedOptions() {
      return this.options.map((x) =>
        this.multi
          ? {
              ...this.optionAdapter(x),
              checked:
                this.value && this.value.length > 0
                  ? typeof this.options[0] !== 'object'
                    ? this.value.includes(x)
                    : this.value.includes(this.optionAdapter(x).id)
                  : false
              // checked: this.value ? (typeof this.value !== 'object' ? [this.value].includes(x) : this.value.includes(x)) : false
            }
          : this.optionAdapter(x)
      )
    },
    computedLabel() {
      let result

      if (this.multi) {
        const value = typeof this.value !== typeof {} ? [this.value] : this.value
        result = this.options.filter((item) =>
          this.value && this.value.length > 0 && typeof this.options[0] === 'object'
            ? value.includes(this.optionAdapter(item).id)
            : value.includes(this.optionAdapter(item).item)
        )

        if (!this.nested) {
          if (this.longMultiLabels) return { label: result ? result.map((option) => this.optionAdapter(option).label).join(', ') : this.placeholder }
          else return { label: result ? result.map((option) => this.optionAdapter(option).label.slice(0, 2)).join(', ') : this.placeholder }
        } else {
          return { label: this.container.length ? this.container.map((o) => o.label).join(', ') : this.placeholder }
        }
      } else {
        if (typeof this.value === typeof {}) result = this.options.find((item) => this._.isEqual(this.optionAdapter(item).item, this.value))
        else result = this.options.find((item) => this.optionAdapter(item).id === this.value)

        return result
          ? this.optionAdapter(result).addition
            ? {
                label: this.optionAdapter(result).label,
                addition: this.optionAdapter(result).addition
              }
            : { label: this.optionAdapter(result).label }
          : { label: this.placeholder }
      }
    },
    isMobile() {
      return this.$screen.width <= 991
    },
    showIcon() {
      return !!this.$slots['preIcon']
    }
  },
  created() {
    const self = this
    const childs = []

    function getChilds(options) {
      options.forEach((o) => {
        childs.push(self.optionAdapter(o))

        if (o.options && o.options.length > 0) getChilds(o.options)
      })
    }
    if (this.value && typeof this.value === typeof [] && this.value.length > 0) {
      this.computedOptions.forEach((o) => {
        if (this.value.includes(o.id)) childs.push(o)
        if (o.options && o.options.length > 0) getChilds(o.options)
      })

      this.$set(this, 'container', childs)
    }
  },
  mounted() {
    this.isTransitioning = false
  },
  methods: {
    toggle() {
      if (!this.disabled) this.isOpen = !this.isOpen
      this.$emit('click')
    },
    close() {
      this.isOpen = false
    },
    change(option) {
      const self = this
      let item = this.computedOptions.find((x) => x.id === option.id)
      const childs = []

      function getValue(options) {
        const newItem = options.find((x) => x.id === option.id)

        if (!newItem) options.map((x) => getValue(x.options))
        else item = newItem
      }

      function getChilds(options) {
        options.forEach((o) => {
          childs.push(self.optionAdapter(o))

          if (o.options && o.options.length > 0) getChilds(o.options)
        })
      }

      if (!item && this.nested) {
        this.computedOptions.map((x) => getValue(x.options))
      }

      const value = item && item.id

      if (!this.multi) {
        this.isOpen = false
        this.$emit('change', value)
      } else {
        if (option.checked) {
          console.log(option)
          if (!!option.options && option.options.length > 0) {
            getChilds(option.options)
            childs.forEach((o) => (this.value.indexOf(o.id) === -1 ? this.container.push(o) : false))
            this.$emit('change', [...this.value, value, ...childs.map((o) => o.id)])
          } else {
            this.$emit('change', [...this.value, value])
          }
          this.container.push(option)
        } else {
          if (!!option.options && option.options.length > 0) {
            getChilds(option.options)
            this.$emit(
              'change',
              this.value.filter((x) => ![...childs.map((o) => o.id)].includes(x) && x !== value)
            )
            childs.forEach((o) =>
              this.container.splice(
                this.container.findIndex((i) => i.id === o.id),
                1
              )
            )
          } else {
            this.$emit(
              'change',
              this.value.filter((x) => x !== value)
            )
          }
          this.container.splice(
            this.container.findIndex((o) => o.id === option.id),
            1
          )
        }
      }
    }
  }
}
</script>

<style lang="scss">
.bt-select {
  $b: &;

  position: relative;
  display: block;
  font-size: 1rem;
  text-align: left;

  &__label {
    @include reset;
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
    margin-bottom: $rhythm;
    width: max-content;

    &--inline {
      line-height: $control-height;
      margin-right: $rhythm;
      margin-bottom: 0;
      float: left;
    }
  }

  &__addition {
    font-weight: 400;
    margin-left: $rhythm;
  }

  &__toggle {
    position: relative;
    padding: 1rem 1rem 0;
    border-radius: $radius;
    z-index: 3;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: $control-height;

    &__pre__icon {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 14px;
      transform: translate3d(0, -50%, 0);
      width: $rhythm * 2;
      height: $rhythm * 2;
      fill: $color--grey;
      font-size: 11px;
      line-height: 16px;

      .icon {
        width: 1rem;
        height: 1rem;
      }
    }

    &--large {
      padding: 1rem 1.5rem;
      height: 4.2rem;
    }

    &__value {
      flex: 1 1 auto;
      text-overflow: ellipsis;
      overflow: hidden;
      /* display: flex; */
      background-color: white;
      padding-bottom: $rhythm * 2;
      margin-bottom: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 0.5rem;

      &--icon {
        display: inline-block;
        padding-left: 1.5rem;
        vertical-align: middle;
      }
    }

    &__icon {
      flex-shrink: 0;
      width: 0.65rem;
      height: 1rem;
      margin-bottom: 1rem;
      fill: $color--primary;
      @include transition--primary;
    }

    &:hover,
    &:focus {
      &__icon {
        fill: $color--primary;
      }
    }
  }

  &--thin {
    max-width: 200px;
  }

  &#{$b}--white {
    #{$b}__dropdown {
      border-color: transparent;
    }

    &#{$b}--open {
      #{$b}__dropdown {
        box-shadow: $shadow--sm;
      }
    }
  }

  &#{$b}--grey {
    #{$b}__dropdown {
      background-color: $color--grey--extra-light;
      border-color: transparent;
    }
  }

  &#{$b}--strong {
    @include font--global--strong;
  }

  &__dropdown {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: $radius;
    z-index: 2;
    background-color: $color--white;
    border: 1px solid $color--line;
    @include transition--primary;
    padding: 48px 0.5rem 0;

    &--large {
      padding: 4rem 0.5rem 0;
    }

    &--label {
      top: 26px;
    }

    &__inner {
      mask-image: linear-gradient(to top, rgba(black, 0), rgba(black, 1) 1rem, rgba(black, 1));
      max-height: 180px;
      overflow: auto;
    }

    &__list {
      position: relative;
      @include reset;
      list-style: none;

      #{$b}__dropdown__list {
        padding-bottom: 0 !important;
        padding-left: $rhythm * 2;

        &:first-child:before {
          content: none;
          display: none;
        }
      }

      &__item {
        cursor: pointer;
        @include transition--primary;

        &--active {
          display: none;
        }

        .bt-checkbox--disabled {
          cursor: pointer !important;
        }

        &:last-child {
          padding-bottom: 0.5rem;
        }

        &__label--mobile {
          display: flex;
        }

        &--mobile {
          display: flex;
          align-items: center;
          height: 5.5rem;
          border: 1px solid $color--line;
          border-radius: $radius;
          padding: 0 1.5rem;
          margin-bottom: $rhythm;

          &--checked {
            border: 2px solid $color--primary;
          }
        }

        &__addition--mobile {
          font-weight: 400;
          margin-left: $rhythm;
        }

        .bt-checkbox {
          margin-left: auto;
        }
      }

      &__button {
        display: flex !important;
        @include reset;
        text-align: left;
        padding: 0.4rem 0.5rem;

        &:hover,
        &:focus {
          color: $color--primary;
        }
      }
    }

    &__sep {
      @include reset;
      border-top: 1px solid $color--line;
      margin: 0.5rem 0;
    }
  }

  &__status-icon {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-left: $rhythm * 2;
    &--open {
      background-color: #66b31b;
    }
    &--close {
      background-color: $color--red;
    }
    &--in-preparation,
    &--temporarily-closed {
      background-color: $color--orange;
    }
  }

  &--inline {
    display: inline-block;
    width: 180px;
  }

  &--disabled {
    opacity: 0.5;

    #{$b}__toggle {
      cursor: not-allowed;
    }
  }

  &--open,
  &--transitioning {
    z-index: 100;
  }
}
</style>
