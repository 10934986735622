import api from '@/api'
import i18n from '@/setup/i18n'
import router from '../../router'

const state = {
  workspaces: [],
  workspaceToEdit: null,
  currentWorkspace: JSON.parse(localStorage.getItem('currentWorkspace')) || null,
  flatWorkspaces: null,
  showWorkspaceCreator: false,
  showSettingsCreator: false,
  showWorkspaceEditor: false,
  workspaceDraggingState: false,
  showDeleteWorkspaceDialog: false,
  notifications: null,
  status: 'DEFAULT'
}

const mutations = {
  SET_WORKSPACES(state, payload) {
    state.workspaces = payload
  },

  SET_SHOW_WORKSPACE_CREATOR(state, payload) {
    state.showWorkspaceCreator = payload
  },

  SET_SHOW_SETTINGS_CREATOR(state, payload) {
    state.showSettingsCreator = payload
  },

  SET_SHOW_WORKSPACE_EDITOR(state, payload) {
    state.showWorkspaceEditor = payload
  },

  SET_FLAT_WORKSPACES(state, payload) {
    state.flatWorkspaces = payload
  },

  SET_WORKSPACE_TO_EDIT(state, payload) {
    state.workspaceToEdit = payload
  },

  SET_CURRENT_WORKSPACES(state, payload) {
    state.currentWorkspace = payload
    if (payload) localStorage.setItem('currentWorkspace', JSON.stringify(payload))
    else localStorage.removeItem('currentWorkspace')
  },

  TOGGLE_CHECKBOX(state, payload) {
    if (!state.flatWorkspaces) return

    state.flatWorkspaces = state.flatWorkspaces.map((el) => {
      if (el.hasOwnProperty('id')) {
        if (el.id === payload.id) el.selected = payload.selected
      } else {
        if (el.mac === payload.id) el.selected = payload.selected
      }

      return el
    })
  },

  TOGGLE_ALL_CHECKBOXES(state, payload) {
    if (!state.flatWorkspaces) return

    const checkAll = (places, selected) => {
      places.forEach((el) => {
        el.selected = selected
        if (el.subLocations.length) checkAll(el.subLocations, payload.selected)
      })
    }

    state.flatWorkspaces = state.flatWorkspaces.map((el) => {
      if (el.hasOwnProperty('id')) {
        if (el.id === payload.id) {
          el.selected = payload.selected
          if (el.subLocations.length) checkAll(el.subLocations, payload.selected)
        }
      } else {
        if (el.mac === payload.id) el.selected = payload.selected
      }

      return el
    })
  },

  TOGGLE_DELETE_WORKSPACE_DIALOG(state, payload) {
    state.showDeleteWorkspaceDialog = payload
  },

  TOGGLE_WORKSPACE_DRAG_STATE(state, payload) {
    state.workspaceDraggingState = payload
  },

  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },

  SET_STATUS(state, payload) {
    state.status = payload
  }
}

const actions = {
  fetchWorkspaces({ commit, dispatch }) {
    // commit('appshell/setDataFetchingState', true, { root: true })

    return new Promise((resolve, reject) => {
      api.workspaces
        .getPlaceStructure()
        .then((response) => {
          const workspaces = response.data

          if (!state.workspaces.length) {
            const firstWorkspace = workspaces[0]
            if (!state.currentWorkspace) commit('SET_CURRENT_WORKSPACES', firstWorkspace)
            if (router.currentRoute.name.includes('Location') && router.currentRoute.name !== 'Location') {
              router.push({
                name: router.currentRoute.name,
                params: { id: firstWorkspace.id }
              })
            } else {
              router.push({
                name: 'LocationDashboard',
                params: { id: firstWorkspace.id }
              })
            }

            resolve()
          }

          commit('SET_WORKSPACES', workspaces)
          commit('appshell/setDataFetchingState', false, { root: true })
        })
        .catch((error) => {
          // // commit('appshell/setDataFetchingState', true, { root: true })
          console.log('fetchWorkspaces: ', error)

          reject()
        })
    })
  },

  fetchCurrentWorkspace({ rootState, commit }) {
    const currentWorkspace = rootState.workspaces.currentWorkspace

    if (currentWorkspace) {
      return new Promise((resolve) => {
        api.workspaces
          .getPlace(currentWorkspace.id)
          .then((response) => {
            commit('SET_CURRENT_WORKSPACES', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            console.log('fetchCurrentWorkspace: ', error)
          })
      })
    }
  },

  getPlaceById({ rootState, commit }, payload) {
    return new Promise((resolve) => {
      api.workspaces
        .getPlace(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          console.log('getPlaceById: ', error)
        })
    })
  },

  addWorkspace({ commit, dispatch }, payload) {
    commit('SET_STATUS', 'LOADING')
    return new Promise((resolve) => {
      api.workspaces
        .addNewPlace(payload)
        .then((response) => {
          dispatch('fetchWorkspaces')
          commit('SET_CURRENT_WORKSPACES', response.data)
          commit('SET_STATUS', 'DEFAULT')
          resolve()
        })
        .catch((error) => {
          console.log('addWorkspace: ', error.response?.data?.code)
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('objects.addError')
          })
          commit('SET_STATUS', 'DEFAULT')
        })
    })
  },

  addSubWorkspace({ commit, dispatch }, payload) {
    commit('SET_STATUS', 'LOADING')
    return new Promise((resolve) => {
      api.workspaces
        .addNewSubPlace(payload.parentId, payload.data)
        .then((response) => {
          dispatch('fetchWorkspaces')
          commit('SET_CURRENT_WORKSPACES', response.data)
          commit('SET_STATUS', 'DEFAULT')
          resolve()
        })
        .catch((error) => {
          console.log('addSubWorkspace: ', error)
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.t('objects.addError')
          })
          commit('SET_STATUS', 'DEFAULT')
        })
    })
  },

  updateWorkspace({ commit, dispatch }, payload) {
    if (!payload) return

    commit('SET_STATUS', 'LOADING')
    return new Promise((resolve) => {
      api.workspaces
        .updatePlace(payload.locationId, payload.data)
        .then(() => {
          dispatch('fetchWorkspaces')
          commit('SET_NOTIFICATIONS', {
            type: 'success',
            message: i18n.t('objects.updateSuccess')
          })
          commit('SET_STATUS', 'DEFAULT')
          resolve()
        })
        .catch((error) => {
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('objects.updateError')
          })
          commit('SET_STATUS', 'DEFAULT')
        })
    })
  },

  updateOrder({ rootState, commit, dispatch }, { locationId, data }) {
    commit('SET_STATUS', 'LOADING')
    return new Promise((resolve) => {
      api.workspaces
        .updateOrder(locationId, data)
        .then((res) => {
          dispatch('fetchWorkspaces')
          commit('SET_NOTIFICATIONS', {
            type: 'success',
            message: i18n.t('objects.updateSuccess')
          })
          commit('SET_STATUS', 'DEFAULT')

          const currentWorkspace = { ...rootState.workspaces.currentWorkspace }

          if (locationId === currentWorkspace.id) {
            currentWorkspace.subLocations = res.data.map((id) => currentWorkspace.subLocations.find((o) => o.id === id))

            commit('SET_CURRENT_WORKSPACE', currentWorkspace)
          }

          resolve()
        })
        .catch((error) => {
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('objects.updateError')
          })
          commit('SET_STATUS', 'DEFAULT')
        })
    })
  },

  removeWorkspace({ commit, dispatch }, payload) {
    commit('TOGGLE_DELETE_WORKSPACE_DIALOG', false)
    commit('SET_STATUS', 'LOADING')

    api.workspaces
      .deletePlace(payload)
      .then(() => {
        dispatch('fetchWorkspaces')
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: i18n.t('objects.removeSuccess')
        })
        commit('SET_STATUS', 'DEFAULT')
      })
      .catch((error) => {
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('objects.removeError')
        })
        commit('SET_STATUS', 'DEFAULT')
        console.log('removeWorkspace: ', error)
      })
  },

  updateMultipleWorkspaces({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api.workspaces
        .updateMultiplePlaces(payload)
        .then(() => {
          dispatch('fetchWorkspaces')
          commit('SET_NOTIFICATIONS', {
            type: 'success',
            message: i18n.t('objects.settingsCreator.success')
          })
          resolve()
        })
        .catch((error) => {
          console.error('updateMultiplePlaces: ', error)
          commit('SET_NOTIFICATIONS', {
            type: 'error',
            message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('objects.settingsCreator.error')
          })
          reject()
        })
    })
  }
}

const getters = {
  workspaces(state) {
    return state.workspaces
  },
  flatWorkspaces(state) {
    return state.flatWorkspaces
  },
  currentWorkspace(state) {
    return state.currentWorkspace
  },
  workspaceToEdit(state) {
    return state.workspaceToEdit
  },
  currentWorkspaceId(state) {
    return state.currentWorkspace ? state.currentWorkspace.id : null
  },
  workspaceDraggingState(state) {
    return state.workspaceDraggingState
  },
  isWorkspaceChecked:
    ({ flatWorkspaces }) =>
    (id) => {
      if (!flatWorkspaces) return false
      const workspace = flatWorkspaces.find((el) => el.id === id)
      return workspace.hasOwnProperty('selected') ? workspace.selected : false
    },
  showDeleteWorkspaceDialog(state) {
    return state.showDeleteWorkspaceDialog
  },
  notifications(state) {
    return state.notifications
  }
}

export default { namespaced: true, state, getters, actions, mutations }
