import Vue from 'vue'
import Vuex from 'vuex'
import undoRedo from 'undo-redo-vuex'
import account from './modules/account'
import users from './modules/users'
import appshell from './modules/appshell'
import media from './modules/media'
import workspaces from './modules/workspaces'
import forms from './modules/forms'
import announcements from './modules/announcements'
import widgets from './modules/widgets'
import weatherStations from './modules/weatherStations'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    undoRedo({
      paths: [
        {
          namespace: 'infoPages',
          ignoreMutations: ['setCategory', 'setScreenshot', 'markAsDraft', 'publish']
        },
        {
          namespace: 'dsBuilderApp',
          ignoreMutations: []
        }
      ]
    })
  ],
  modules: {
    appshell,
    account,
    users,
    workspaces,
    media,
    account,
    forms,
    announcements,
    widgets,
    weatherStations
  }
})

export default store
