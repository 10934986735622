import api from '@/api'

const state = {
  playlists: [],
  playlist: null,
  showPlaylistEditor: false,
  showRemovePlaylistModal: false
}

const mutations = {
  /**
   * Set playlists
   *
   * @param {Object} payload
   */
  SET_PLAYLISTS(state, payload) {
    state.playlists = payload
  },

  /**
   * Add the new playlist to the items array
   *
   * @param {Object} payload
   */
  ADD_PLAYLIST_TO_ITEMS_ARRAY(rootState, payload) {
    const index = payload.index === 0 ? payload.index : payload.index + 1
    rootState.items.splice(index, 0, payload.playlist)
  },

  /**
   * Add the new playlist to the images array on a specific place
   *
   * @param {Object} payload
   */
  ADD_PLAYLIST_TO_IMAGES_ARRAY(rootState, payload) {
    const index = payload.index === 0 ? payload.index : payload.index + 1
    rootState.images.splice(index, 0, payload.playlist)
  },

  /**
   * Add the new playlist to the videos array on a specific place
   *
   * @param {Object} payload
   */
  ADD_PLAYLIST_TO_VIDEOS_ARRAY(rootState, payload) {
    const index = payload.index === 0 ? payload.index : payload.index + 1
    rootState.videos.splice(index, 0, payload.playlist)
  },

  /**
   * Set showRemovePlaylistModal state
   *
   * @param {Boolean} payload
   */
  TOGGLE_REMOVE_PLAYLIST_MODAL(state, payload) {
    state.showRemovePlaylistModal = payload
  },

  /**
   * Set showPlaylistEditor state
   *
   * @param {Boolean} payload
   */
  TOGGLE_PLAYLIST_EDITOR(state, payload) {
    state.showPlaylistEditor = payload
  }
}

const actions = {
  /**
   * Fetch playlists from the server
   */
  fetchPlaylists({ commit, rootState }) {
    const currentWorkspaceId = rootState.workspaces.currentWorkspace ? rootState.workspaces.currentWorkspace.id : null

    api.media
      .getAllPlaylists(currentWorkspaceId)
      .then((response) => {
        commit('SET_PLAYLISTS', response.data)
      })
      .catch((error) => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Items has not been loaded!` })
        console.log('fetchPlaylists: ', error)
      })
  },

  /**
   * Fetch playlist by Id from the server
   *
   * @param {Number} payload
   */
  fetchPlaylistById({ commit }, payload) {
    if (!payload) return

    api.media
      .getPlaylistById(payload)
      .then((response) => {
        commit('SET_PLAYLIST', response.data)
      })
      .catch((error) => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Itens has not been loaded!` })
        console.log('fetchPlaylistById: ', error)
      })
  },

  /**
   * Create playlist
   *
   * @param {Object} payload
   */
  createPlaylist({ commit, rootState }, payload) {
    if (!payload) return

    const currentWorkspaceId = rootState.workspaces.currentWorkspace ? rootState.workspaces.currentWorkspace.id : null
    const mediaIds = payload.medias.map((medium) => medium.id)

    api.media
      .createPlayList(currentWorkspaceId, { name: payload.name, contentType: payload.contentType, mediaIds })
      .then((response) => {
        const playlist = response.data
        commit('ADD_PLAYLIST_TO_ITEMS_ARRAY', { index: payload.index, playlist })
        commit('SET_NOTIFICATIONS', { type: 'success', message: `The playlist has been created!` })
      })
      .catch((error) => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... The playlist has not been created!` })
        console.log('createPlaylist: ', error)
      })
  },

  /**
   * Update playlist
   *
   * @param {Object} payload
   */
  editPlaylist({ commit, dispatch }, payload) {
    if (!payload) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... The playlist has not been edited!` })
      return
    }

    commit('SET_ITEMS_LOADING', true)

    return new Promise((resolve, reject) => {
      api.media
        .updatePlayList(payload.id, payload.obj)
        .then((response) => {
          commit('SET_ITEM_TO_EDIT', response.data)
          dispatch('media/fetchItems', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: `The playlist has been updated!` })
          resolve()
        })
        .catch((error) => {
          commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... The playlist has not been updated!` })
          console.log('updatePlayList: ', error)
          reject()
        })
        .then(() => {
          commit('TOGGLE_EDIT_MEDIUM_MODAL', false)
          commit('SET_ITEMS_LOADING', false)
        })
    })
  },

  /**
   * Update playlist
   *
   * @param {Object} payload
   */
  editPlaylistOrder({ commit, dispatch }, payload) {
    if (!payload) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... The playlist has not been edited!` })
      return
    }

    commit('SET_ITEMS_LOADING', true)

    api.media
      .updatePlayListContentOrder(payload.id, payload.mediaIds)
      .then((response) => {
        commit('SET_ITEM_TO_EDIT', response.data)
        dispatch('media/fetchItems', null, { root: true })
        commit('SET_NOTIFICATIONS', { type: 'success', message: `The playlist order has been updated!` })
      })
      .catch((error) => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... The playlist order has not been updated!` })
        console.log('updatePlayListContentOrder: ', error)
      })
      .then(() => {
        commit('TOGGLE_EDIT_MEDIUM_MODAL', false)
        commit('SET_ITEMS_LOADING', false)
      })
  },

  /**
   * Add content to playlist
   *
   * @param {Object} payload
   */
  addContentToPlaylist({ commit, dispatch, state }, payload) {
    if (!payload) return

    api.media
      .addContentToPlaylist(payload.playlistId, payload.mediaId)
      .then((response) => {
        dispatch('media/fetchItems', null, { root: true })
        commit('SET_NOTIFICATIONS', { type: 'success', message: `The playlist has been updated!` })
        if (state.itemToEdit) commit('SET_ITEM_TO_EDIT', response.data)
      })
      .catch((error) => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... The playlist has not been updated!` })
        console.log('addContentToPlaylist: ', error)
      })
  },

  /**
   * Remove content from playlist
   *
   * @param {Object} payload
   */
  removeContentFromPlaylist({ commit, dispatch }, payload) {
    if (!payload) return

    api.media
      .deleteContentFromPlaylist(payload.playlistId, payload.mediaId)
      .then((response) => {
        commit('SET_NOTIFICATIONS', { type: 'success', message: `The playlist has been updated!` })
        dispatch('media/fetchItems', null, { root: true })
        commit('SET_ITEM_TO_EDIT', response.data)
      })
      .catch((error) => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... The playlist has not been updated!` })
        console.log('removeContentFromPlaylist: ', error)
      })
  },

  /**
   * Delete playlist
   *
   * @param {Number} payload
   */
  removePlaylist({ commit, dispatch, state }, payload) {
    if (!payload) return

    commit('appshell/startLoading', false, { root: true })

    api.media
      .deletePlayList(payload.id)
      .then(() => {
        dispatch('media/fetchItems', null, { root: true })
        commit('TOGGLE_REMOVE_PLAYLIST_MODAL', false)
        commit('TOGGLE_CLOSE_EDIT_MEDIUM_MODAL', false)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `The playlist has been deleted!` })
        commit('appshell/endLoading', false, { root: true })
        commit('CLEAR_SELECTED_ITEMS')

        if (state.itemToEdit && payload.id === state.itemToEdit.id) {
          commit('TOGGLE_PLAYLIST_EDITOR', false)
        }
      })
      .catch((error) => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... The playlist has not been deleted!` })
        commit('appshell/endLoading', false, { root: true })
        console.log('removePlaylist: ', error)
      })
  },

  /**
   * Approve  playlist
   *
   * @param {Number} payload
   */
  approvePlaylist({ commit, dispatch, state }, payload) {
    if (!payload) return

    commit('appshell/startLoading', false, { root: true })

    api.media
      .approvePlayList(payload.id)
      .then((response) => {
        dispatch('media/fetchItems', null, { root: true })
        commit('SET_ITEM_TO_EDIT', response.data)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `The playlist has been approved!` })
        commit('appshell/endLoading', false, { root: true })
        commit('CLEAR_SELECTED_ITEMS')
      })
      .catch((error) => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... The playlist has not been approved!` })
        commit('appshell/endLoading', false, { root: true })
        console.log('approvePlaylist: ', error)
      })
  },

  /**
   * Delete playlist
   *
   * @param {Number} payload
   */
  removePlaylists({ commit }, payload) {
    if (!payload) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Playlists have not been removed!` })
      return
    }

    const ids = payload.map((obj) => obj.id)

    if (!ids.length) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Playlists have not been removed!` })
      return
    }

    commit('SET_ITEMS_LOADING', true)
    commit('appshell/startLoading', false, { root: true })

    api.media
      .deletePlayLists(ids)
      .then(() => {
        commit('REMOVE_ITEMS', ids)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `Playlists have been deleted!` })
      })
      .catch((error) => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Playlists have not been deleted!` })
        console.log('removePlaylists: ', error)
      })
      .then(() => {
        commit('CLEAR_SELECTED_ITEMS')
        commit('SET_ITEMS_LOADING', false)
        commit('TOGGLE_REMOVE_ITEMS_MODAL', false)
        commit('appshell/endLoading', false, { root: true })
      })
  }
}

const getters = {
  playlists(state) {
    return state.playlists
  },
  playlist(state) {
    return state.playlist
  },
  showRemovePlaylistModal(state) {
    return state.showRemovePlaylistModal
  },
  showPlaylistEditor(state) {
    return state.showPlaylistEditor
  }
}

export const playlists = { state, mutations, actions, getters }
