<template lang="pug">
// Component uses loading prop to specify whether or not the action it's responsible for is performing

bt-button(:class="{ 'button--is-disabled': loading }", @click="$emit('click')")
  svg.icon.icon--small(v-if="loading", v-html="require('@/assets/img/loader.svg?inline')")

  // Button text/content goes to the slot
  slot(v-else)
</template>

<script>
export default {
  name: 'Button',
  props: {
    loading: {
      type: Boolean,
      require: true
    }
  }
}
</script>
