import api from '@/api'

import i18n from '@/setup/i18n'

const state = {
  user: null,
  showEmailSentModal: false,
  showEmailConfirmationModal: false,
  notifications: null,
  shouldFetchUser: false
}

const mutations = {
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_USER_DATA(state, data) {
    state.user = data
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },
  SET_SHOULD_FETCH_USER(state, payload) {
    state.shouldFetchUser = payload
  },
  TOGGLE_EMAIL_CONFIRMATION_MODAL(state, payload) {
    state.showEmailConfirmationModal = payload
  },
  TOGGLE_EMAIL_SENT_MODAL(state, payload) {
    state.showEmailSentModal = payload
  }
}

const actions = {
  fetchUser({ commit }) {
    api.account
      .fetchUser()
      .then((response) => {
        commit('SET_USER', response.data)
      })
      .catch((error) => {
        console.log('fetchUser: ', error.message)
      })
  },

  updateUser({ commit }, payload) {
    if (!payload) return

    const formData = new FormData()

    formData.append('request', new Blob([JSON.stringify(payload)], { type: 'application/json' }))
    formData.append('avatar', null)

    api.account
      .editProfile(formData)
      .then((response) => {
        commit('SET_USER', response.data)
        commit('SET_SHOULD_FETCH_USER', true)
        commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('account.updateSuccess') })
      })
      .catch((error) => {
        console.log('updateUser: ', error.message)
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('account.updateError')
        })
      })
  },

  changePassword({ commit }, payload) {
    if (!payload) return

    commit('appshell/startLoading', true, { root: true })

    api.account
      .editPassword(payload)
      .then((response) => {
        commit('SET_SHOULD_FETCH_USER', true)
        commit('SET_NOTIFICATIONS', { type: 'success', message: i18n.t('account.updatePasswordSuccess') })
        commit('appshell/startLoading', false, { root: true })
      })
      .catch((error) => {
        console.log('changePasword: ', error.message)
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: i18n.te(`api.${error.response?.data?.code}`) ? i18n.t(`api.${error.response.data.code}`) : i18n.t('account.updatePasswordError')
        })
        commit('appshell/startLoading', false, { root: true })
      })
  }
}

const getters = {
  user: (state) => state.user,
  showEmailSentModal: (state) => state.showEmailSentModal,
  showEmailConfirmationModal: (state) => state.showEmailConfirmationModal,
  notifications: (state) => state.notifications,
  shouldFetchUser: (state) => state.shouldFetchUser,
  getData: (state) => state.data,
  isAuthenticated: (state) => state.isAuthenticated
}

export default { namespaced: true, state, getters, actions, mutations }
