import i18n from '@/setup/i18n'

export const fields = {
  widgetRanges: [
    {
      name: i18n.t('widgets.fields.range.options.wholeRegion'),
      value: true,
      icon: 'track'
    },
    {
      name: i18n.t('widgets.fields.range.options.selectedStations'),
      value: false,
      icon: 'operator-station'
    }
  ],
  weatherFields: [
    {
      name: i18n.t('forms.fields.sun.label'),
      value: 'sun'
    },
    {
      name: 'Temperatura',
      options: [
        {
          name: i18n.t('forms.fields.temperature.label'),
          value: 'temperature'
        },
        {
          name: i18n.t('forms.fields.perceivedTemperature.label'),
          value: 'perceivedTemperature'
        }
      ]
    },
    {
      name: i18n.t('forms.fields.humidity.label'),
      value: 'humidity'
    },
    {
      name: 'Opady',
      options: [
        {
          name: i18n.t('forms.fields.precipitationType.label'),
          value: 'precipitationType'
        },
        {
          name: i18n.t('forms.fields.precipitationAmount.label'),
          value: 'precipitation'
        }
      ]
    },
    {
      name: i18n.t('forms.fields.snowCoverThickness.label'),
      value: 'snowCover'
    },
    {
      name: 'Wiatr',
      options: [
        {
          name: i18n.t('forms.fields.windStrength.label'),
          value: 'windStrength'
        },
        {
          name: i18n.t('forms.fields.windDirection.label'),
          value: 'windDirection'
        }
      ]
    },
    {
      name: i18n.t('forms.fields.atmosphericPressure.label'),
      value: 'atmosphericPressure'
    },
    {
      name: i18n.t('forms.fields.avalancheDanger.label'),
      value: 'avalancheDanger'
    }
  ],
  operatorStationFields: [
    {
      name: i18n.t('objects.operatorStation.name'),
      value: 'name'
    },
    {
      name: i18n.t('objects.operatorStation.height'),
      value: 'height'
    },
    {
      name: i18n.t('objects.operatorStation.geoLocation'),
      value: 'geoLocation'
    },
    {
      name: i18n.t('objects.operatorStation.parking'),
      value: 'parking'
    }
  ],
  skiRunFields: [
    {
      name: i18n.t('objects.run.name'),
      value: 'name'
    },
    {
      name: i18n.t('forms.fields.state.label'),
      value: 'state'
    },
    {
      name: i18n.t('forms.fields.skiRunTraffic.label'),
      value: 'skiRunTraffic'
    },
    {
      name: i18n.t('forms.fields.snowCoverThickness.label'),
      value: 'snowCover'
    },
    {
      name: i18n.t('objects.run.category'),
      value: 'category'
    },
    {
      name: i18n.t('objects.run.length'),
      value: 'length'
    },
    {
      name: i18n.t('objects.run.elevationDifference'),
      value: 'elevationDifference'
    },
    {
      name: i18n.t('objects.run.averageAngle'),
      value: 'tilt'
    },
    {
      name: i18n.t('objects.run.gastronomy'),
      value: 'gastronomicInfrastructure'
    },
    {
      name: i18n.t('objects.run.otherRecreations'),
      value: 'recreationalInfrastructure'
    },
    {
      name: i18n.t('objects.run.sportInfrastructure'),
      value: 'sportInfrastructure'
    }
  ],
  skiLiftFields: [
    {
      name: i18n.t('objects.lift.name'),
      value: 'name'
    },
    {
      name: i18n.t('forms.fields.status.label'),
      value: 'status'
    },
    {
      name: i18n.t('objects.lift.type'),
      value: 'liftType'
    },
    {
      name: i18n.t('objects.lift.maximumCapacity'),
      value: 'peoplePerHour'
    },
    {
      name: i18n.t('objects.lift.lastDeparture'),
      value: 'lastRunUp'
    },
    {
      name: i18n.t('objects.lift.length'),
      value: 'length'
    },
    {
      name: i18n.t('objects.lift.departureDuration'),
      value: 'rideTime'
    },
    {
      name: i18n.t('objects.lift.openHours'),
      value: 'openHours'
    }
  ],

  announcementFields: [
    {
      name: 'Komunikaty Reklamowo-Sprzedażowe',
      value: 'advertising'
    },
    {
      name: 'Komunikaty Promocyjne',
      value: 'promotional'
    }
  ],

  widgetTypes: [
    {
      hasSideBar: false,
      value: 'WEB',
      image: 'widget-web.png',
      name: i18n.t('widgets.fields.type.options.web')
    },
    {
      hasSideBar: false,
      value: 'SIGNAGE',
      image: 'widget-signage.png',
      name: i18n.t('widgets.fields.type.options.signage')
    }
  ],
  widgetThemes: [
    {
      name: i18n.t('widgets.fields.theme.options.light'),
      value: 'LIGHT'
    },
    {
      name: i18n.t('widgets.fields.theme.options.dark'),
      value: 'DARK'
    }
  ],
  cornerTypes: [
    {
      name: i18n.t('widgets.fields.style.options.rounded'),
      value: true
    },
    {
      name: i18n.t('widgets.fields.style.options.sharp'),
      value: false
    }
  ],
  backgroundTransparentTypes: [
    {
      name: i18n.t('widgets.fields.background.options.solid'),
      value: false
    },
    {
      name: i18n.t('widgets.fields.background.options.transparent'),
      value: true
    }
  ]
}
