import axios from 'axios'

export default {
  createLocationForm: (locationId, data) => axios.post(`/api/v1/forms/location/${locationId}`, data),
  getLocationsForm: (locationId) => axios.get(`/api/v1/forms/location/${locationId} `),
  getSubLocationsForm: (locationId) => axios.get(`/api/v1/forms/location/${locationId}/states`),
  getFormsToApprove: (locationId) => axios.get(`/api/v1/forms/location/${locationId}/to-approve`),
  approveForm: (formId) => axios.put(`/api/v1/forms/${formId}/approve`),
  rejectForm: (formId) => axios.put(`/api/v1/forms/${formId}/reject`),
  getFormToFill: (token) => axios.get(`/api/external/v1/forms/fill?token=${token}`),
  sendFormToFill: (token, locationId, data) => axios.post(`/api/external/v1/location/${locationId}/forms/send?token=${token}`, data),
  getFormHistory: (locationId, page = 1, pageSize = 100) => axios.get(`/api/v1/forms/location/${locationId}/history?page=${page}&pageSize=${pageSize}`)
}
