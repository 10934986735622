import axios from 'axios'

export default {
  getAvailableStations: () => axios.get('/api/v1/widgets/available-stations'),
  getAllWidgets: () => axios.get('/api/v1/widgets'),
  getPreview: (stationId) => axios.get(`api/v1/widgets/preview/${stationId}`),
  createWidget: (data) => axios.post('/api/v1/widgets', data),
  deleteWidget: (widgetId) => axios.delete(`/api/v1/widgets/${widgetId}`),
  updateWidget: (widgetId, data) => axios.put(`/api/v1/widgets/${widgetId}`, data),
  activateWidget: (widgetId) => axios.put(`/api/v1/widgets/${widgetId}/activate`),
  deactivateWidget: (widgetId) => axios.put(`/api/v1/widgets/${widgetId}/deactivate`),
  duplicateWidget: (data) => axios.post('/api/v1/widgets', data)
}
