import Vue from 'vue'
import axios from 'axios'

import auth from '@websanova/vue-auth/dist/v2/vue-auth.common.js'
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.js'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js'

import store from '@/store'

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router
  },
  drivers: {
    auth: {
      request: function (req, token) {
        this.drivers.http.setHeaders.call(this, req, {
          Authorization: 'Bearer ' + token
        })
        Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
      },
      response: function (res) {
        return res.data.token
      }
    },
    http: driverHttpAxios,
    router: driverRouterVueRouter
  },
  options: {
    rolesKey: 'type',
    notFoundRedirect: { name: 'user-account' },
    fetchUser: true,
    authRedirect: { path: '/signin' },
    loginData: { url: '/api/v1/auth/signin', fetchUser: true },
    fetchData: { url: '/api/v1/users/profile', enabled: true },
    refreshData: { url: '/refresh', enabled: false },
    staySignedIn: true,
    parseUserData(data) {
      store.commit('account/SET_USER_DATA', data)
      return data || {}
    }
  }
})
